import React, { FunctionComponent, useCallback, useContext } from 'react';
import { Snackbar as MUISnackbar } from '@mui/material';
import { createInitialSnackbarState, UiContext } from '../../contexts';

export const Snackbar: FunctionComponent = () => {
  const { snackbar, setSnackbar } = useContext(UiContext);

  const onClose = useCallback(() => {
    setSnackbar(createInitialSnackbarState());
  }, [setSnackbar]);

  if (!snackbar) {
    return null;
  }

  return (
    <MUISnackbar
      open={true}
      autoHideDuration={6000}
      onClose={onClose}
      message={snackbar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    />
  );
};
