import { useEffect, useState } from 'react';
import { onSnapshot, orderBy, query } from 'firebase/firestore';
import { ModelCategory } from '../../entities';
import { getModelCategoryCollection } from '../../infrastructures/firebase';

export const useFetchModelCategories = (): {
  isFetching: boolean;
  modelCategories: ModelCategory[];
} => {
  const [isFetching, setIsFetching] = useState(true);
  const [modelCategories, setModelCategories] = useState<ModelCategory[]>([]);

  useEffect(() => {
    const q = query(getModelCategoryCollection, orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());

        if (data) {
          setModelCategories(data);
        }

        setIsFetching(false);
      },
      (error) => {
        console.error('catch useFetchModelCategories error: ', error.message);
        setIsFetching(false);
      },
    );

    return unsubscribe;
  }, []);

  return { isFetching, modelCategories };
};
