import { AgencyType } from '../entities/AgencyType';

export const AGENCY_TYPE: AgencyType[] = [
  {
    slug: 'entertainment',
    name: '総合エンタメ',
    minAge: 10,
    maxAge: 25,
    minHeight: 100,
    maxHeight: 175,
  },
  {
    slug: 'clean',
    name: '清楚系女優',
    minAge: 10,
    maxAge: 25,
    minHeight: 100,
    maxHeight: 175,
  },
  {
    slug: 'gravure',
    name: 'グラビア系',
    minAge: 16,
    maxAge: 28,
    minHeight: 140,
    maxHeight: 175,
  },
  {
    slug: 'actor',
    name: 'THE 俳優系',
    minAge: 10,
    maxAge: 30,
    minHeight: 140,
    maxHeight: 175,
  },
  {
    slug: 'fashion',
    name: 'ファッションモデル上がり',
    minAge: 15,
    maxAge: 25,
    minHeight: 150,
    maxHeight: 180,
  },
  {
    slug: 'commercial',
    name: 'コマーシャルモデル上がり',
    minAge: 15,
    maxAge: 28,
    minHeight: 140,
    maxHeight: 175,
  },
  {
    slug: 'idol',
    name: 'アイドル系',
    minAge: 10,
    maxAge: 25,
    minHeight: 100,
    maxHeight: 180,
  },
  {
    slug: 'liver',
    name: 'ライバー系',
    minAge: 16,
    maxAge: 30,
    minHeight: 100,
    maxHeight: 180,
  },
  {
    slug: 'overseas',
    name: 'ファッション海外',
    minAge: 12,
    maxAge: 24,
    minHeight: 160,
    maxHeight: 180,
  },
  {
    slug: 'magazine',
    name: '雑誌系モデル',
    minAge: 10,
    maxAge: 25,
    minHeight: 140,
    maxHeight: 175,
  },
  {
    slug: 'unique',
    name: 'ファッションユニーク',
    minAge: 12,
    maxAge: 24,
    minHeight: 150,
    maxHeight: 178,
  },
  {
    slug: 'largeModelAgency',
    name: '大型モデル事務所',
    minAge: 10,
    maxAge: 29,
    minHeight: 120,
    maxHeight: 175,
  },
  {
    slug: 'registered',
    name: '登録系モデル',
    minAge: 1,
    maxAge: 40,
    minHeight: 50,
    maxHeight: 180,
  },
  {
    slug: 'kids',
    name: 'キッズ',
    minAge: 1,
    maxAge: 15,
    minHeight: 50,
    maxHeight: 150,
  },
  {
    slug: 'authority',
    name: '権力系事務所',
    minAge: 6,
    maxAge: 30,
    minHeight: 100,
    maxHeight: 180,
  },
  {
    slug: 'artist',
    name: 'アーティスト系',
    minAge: 10,
    maxAge: 30,
    minHeight: 100,
    maxHeight: 180,
  },
  {
    slug: 'second',
    name: 'セカンドキャリア',
    minAge: 15,
    maxAge: 100,
    minHeight: 140,
    maxHeight: 180,
  },
];
