import { addErrorEventOnSentry } from '../infrastructures/sentry';

export const getAge = (birthday?: Date): number => {
  try {
    if (!birthday) {
      throw new Error('値がfalsyです');
    }

    //今日
    const today = new Date();

    //今年の誕生日
    const thisYearsBirthday = new Date(
      today.getFullYear(),
      birthday.getMonth(),
      birthday.getDay(),
    );

    //年齢
    let age = today.getFullYear() - birthday.getFullYear();

    if (today < thisYearsBirthday) {
      //今年まだ誕生日が来ていない
      age--;
    }

    return age;
  } catch (e) {
    addErrorEventOnSentry(e);
    return 0;
  }
};
