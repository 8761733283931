import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  type DocumentData,
} from 'firebase/firestore';

export type UserLog = {
  title: string;
  url?: string;
  createdAt: Date;
};

/**
 * Firestore のドキュメントと UserLog オブジェクトの型変換を行います。
 */
export const userLogConverter: FirestoreDataConverter<UserLog> = {
  /**
   * UserLog オブジェクトを Firestore ドキュメントデータへ変換します。
   */
  toFirestore(log: UserLog): DocumentData {
    return {
      title: log.title,
      url: log.url || '',
      createdAt: log.createdAt,
    };
  },

  /**
   * Firestore ドキュメントデータを UserLog オブジェクトへ変換します。
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): UserLog {
    const data = snapshot.data(options);

    return {
      title: data.title,
      url: data.url,
      createdAt: data.createdAt.toDate(),
    };
  },
};
