import React from 'react';
import { Alert, CircularProgress, Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import { PublicUser } from '../../../entities';
import { labels } from '../../../constants';

type UserMissingPropertyAlertProps = {
  publicUser: PublicUser | null;
  isPredicting: boolean;
};

export const UserMissingPropertyAlert: FunctionComponent<
  UserMissingPropertyAlertProps
> = ({ publicUser, isPredicting }) => {
  if (!publicUser) {
    return null;
  }

  return (
    <Stack spacing={1} mt={1} mb={1}>
      {!publicUser.bustupImgUrl && (
        <Alert severity='warning'>
          {labels.data.userImage.bust}が未登録です
        </Alert>
      )}
      {!publicUser.fullbodyImgUrl && (
        <Alert severity='warning'>
          {labels.data.userImage.fullbody}が未登録です
        </Alert>
      )}
      {!publicUser.sideImgUrl && (
        <Alert severity='warning'>
          {labels.data.userImage.side}が未登録です
        </Alert>
      )}
      {!publicUser.smileImgUrl && (
        <Alert severity='warning'>
          {labels.data.userImage.smile}が未登録です
        </Alert>
      )}
      {isPredicting && (
        <Alert
          severity='warning'
          icon={<CircularProgress size={24} color='warning' />}
        >
          ラベルを推論中です
        </Alert>
      )}
    </Stack>
  );
};
