import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { Agency } from '../../entities';
import { getAgencyRef } from '../../infrastructures/firebase';

export const useAgency = (
  agencyId?: string,
): {
  isFetching: boolean;
  agency: Agency | null;
} => {
  const [isFetching, setIsFetching] = useState(true);
  const [agency, setAgency] = useState<Agency | null>(null);

  useEffect(() => {
    if (!agencyId) {
      return;
    }

    const unsubscribe = onSnapshot(
      getAgencyRef(agencyId),
      (snapshot) => {
        const data = snapshot.data();

        if (data) {
          setAgency(data);
        }

        setIsFetching(false);
      },
      (error) => {
        console.error('catch useAgency error: ', error.message);
        setIsFetching(false);
      },
    );

    return unsubscribe;
  }, []);

  return { isFetching, agency };
};
