import React, { FunctionComponent, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { labels } from '../../../constants';
import { ModelCategory, ModelCategoryFormData } from '../../../entities';
import { useModelCategoryEditTools } from '../../../hooks';

type ModelCategoryCommonFormProps = {
  modelCategory: ModelCategory;
  method: 'add' | 'edit';
};

export const ModelCategoryCommonForm: FunctionComponent<
  ModelCategoryCommonFormProps
> = ({ modelCategory, method = 'add' }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    watch,
  } = useForm<ModelCategoryFormData>({
    defaultValues: {
      name: modelCategory.name,
      inheritance: modelCategory.inheritance,
      description: modelCategory.description,
      fashionModelScore: modelCategory.fashionModelScore,
      commercialModelScore: modelCategory.commercialModelScore,
      createdAt: method === 'add' ? new Date() : modelCategory.createdAt,
      updatedAt: new Date(),
    },
  });

  const { addModelCategory, updateModelCategory } = useModelCategoryEditTools();

  const onSubmit: SubmitHandler<ModelCategoryFormData> = async (formData) => {
    // methodに応じて add か update を切り替え
    if (method === 'add') {
      await addModelCategory(formData);
    } else {
      await updateModelCategory(modelCategory.id, formData);
    }
  };

  const navigate = useNavigate();

  const onClickBack = useCallback(() => {
    const isAgree = window.confirm(labels.message.confirmToGoBack);

    if (isAgree) {
      navigate(-1);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* モデルカテゴリーの基本情報に関する項目設定 */}
      <Card>
        <CardHeader title={labels.data.modelCategory.basicInfo} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name='name'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={labels.data.modelCategory.name}
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message}
                  />
                )}
              />
              <Controller
                name='inheritance'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label={labels.data.modelCategory.inheritance}
                    error={Boolean(errors?.inheritance)}
                    helperText={errors?.inheritance?.message}
                  >
                    <MenuItem value='コマーシャルモデル'>
                      コマーシャルモデル
                    </MenuItem>
                    <MenuItem value='ファッションモデル'>
                      ファッションモデル
                    </MenuItem>
                  </TextField>
                )}
              />
              <Controller
                name='description'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={labels.data.modelCategory.description}
                    error={Boolean(errors?.description)}
                    helperText={errors?.description?.message}
                    multiline={true}
                    rows={5}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* レーダーチャートに関する項目設定 */}
      {watch('inheritance') === 'ファッションモデル' && (
        <Card sx={{ mt: 3 }}>
          <CardHeader title={labels.data.modelCategory.fashionModelScore} />
          <Divider />
          <CardContent>
            {/* 身長 */}
            <Controller
              name='fashionModelScore.height'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 10, message: '10以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.height}
                  type='number'
                  error={Boolean(errors?.fashionModelScore?.height)}
                  helperText={errors?.fashionModelScore?.height?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/* 全身バランス */}
            <Controller
              name='fashionModelScore.balance'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 20, message: '20以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.balance}
                  type='number'
                  error={Boolean(errors?.fashionModelScore?.balance)}
                  helperText={errors?.fashionModelScore?.balance?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/* 脚の形 */}
            <Controller
              name='fashionModelScore.legShape'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 30, message: '30以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.legShape}
                  type='number'
                  error={Boolean(errors?.fashionModelScore?.legShape)}
                  helperText={errors?.fashionModelScore?.legShape?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/* 手足の長さ */}
            <Controller
              name='fashionModelScore.limbLength'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 20, message: '20以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.limbLength}
                  type='number'
                  error={Boolean(errors?.fashionModelScore?.limbLength)}
                  helperText={errors?.fashionModelScore?.limbLength?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/* 顔立ち */}
            <Controller
              name='fashionModelScore.features'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 10, message: '10以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.features}
                  type='number'
                  error={Boolean(errors?.fashionModelScore?.features)}
                  helperText={errors?.fashionModelScore?.features?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/* 鼻 */}
            <Controller
              name='fashionModelScore.nose'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 20, message: '20以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.nose}
                  type='number'
                  error={Boolean(errors?.fashionModelScore?.nose)}
                  helperText={errors?.fashionModelScore?.nose?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/* 肌質 */}
            <Controller
              name='fashionModelScore.skinQuality'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 10, message: '10以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.skinQuality}
                  type='number'
                  error={Boolean(errors?.fashionModelScore?.skinQuality)}
                  helperText={errors?.fashionModelScore?.skinQuality?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/* 年齢 */}
            <Controller
              name='fashionModelScore.age'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 10, message: '10以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.age}
                  type='number'
                  error={Boolean(errors?.fashionModelScore?.age)}
                  helperText={errors?.fashionModelScore?.age?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </CardContent>
        </Card>
      )}

      {/* レーダーチャートに関する項目設定 */}
      {watch('inheritance') === 'コマーシャルモデル' && (
        <Card sx={{ mt: 3 }}>
          <CardHeader title={labels.data.modelCategory.commercialModelScore} />
          <Divider />
          <CardContent>
            {/* 顔立ち */}
            <Controller
              name='commercialModelScore.features'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 10, message: '10以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.features}
                  type='number'
                  error={Boolean(errors?.commercialModelScore?.features)}
                  helperText={errors?.commercialModelScore?.features?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {/* 年齢 */}
            <Controller
              name='commercialModelScore.age'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 10, message: '10以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.age}
                  type='number'
                  error={Boolean(errors?.commercialModelScore?.age)}
                  helperText={errors?.commercialModelScore?.age?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {/* 全身バランス */}
            <Controller
              name='commercialModelScore.balance'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 20, message: '20以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.balance}
                  type='number'
                  error={Boolean(errors?.commercialModelScore?.balance)}
                  helperText={errors?.commercialModelScore?.balance?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {/* 脚の形 */}
            <Controller
              name='commercialModelScore.legShape'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 30, message: '30以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.legShape}
                  type='number'
                  error={Boolean(errors?.commercialModelScore?.legShape)}
                  helperText={errors?.commercialModelScore?.legShape?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {/* 笑顔 */}
            <Controller
              name='commercialModelScore.smile'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 20, message: '20以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.smile}
                  type='number'
                  error={Boolean(errors?.commercialModelScore?.smile)}
                  helperText={errors?.commercialModelScore?.smile?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {/* 身長 */}
            <Controller
              name='commercialModelScore.height'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 10, message: '10以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.height}
                  type='number'
                  error={Boolean(errors?.commercialModelScore?.height)}
                  helperText={errors?.commercialModelScore?.height?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {/* 鼻 */}
            <Controller
              name='commercialModelScore.nose'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 20, message: '20以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.nose}
                  type='number'
                  error={Boolean(errors?.commercialModelScore?.nose)}
                  helperText={errors?.commercialModelScore?.nose?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {/* 肌質 */}
            <Controller
              name='commercialModelScore.skinQuality'
              control={control}
              rules={{
                required: labels.form.errors.required,
                min: { value: 0, message: '0以上の数値を入力してください' },
                max: { value: 10, message: '10以下の数値を入力してください' },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={labels.data.scores.skinQuality}
                  type='number'
                  error={Boolean(errors?.commercialModelScore?.skinQuality)}
                  helperText={
                    errors?.commercialModelScore?.skinQuality?.message
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>点</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </CardContent>
        </Card>
      )}

      {/* フォームアクション */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          mx: -1,
          mb: -1,
          mt: 3,
        }}
      >
        <Button sx={{ m: 1 }} variant='outlined' onClick={onClickBack}>
          {labels.form.actions.back}
        </Button>

        {isSubmitting ? (
          <Button sx={{ m: 1 }} variant='contained' disabled>
            {labels.form.actions.loading}
          </Button>
        ) : (
          <Button
            sx={{ m: 1 }}
            type='submit'
            variant='contained'
            disabled={!isDirty}
          >
            {labels.form.actions.save}
          </Button>
        )}
      </Box>
    </form>
  );
};
