import {
  signInWithEmailAndPassword,
  signOut as SignOutOnFirebase,
} from 'firebase/auth';
import { useContext } from 'react';
import { labels } from '../../constants';
import { UiContext } from '../../contexts';
import {
  auth as FirebaseAuth,
  getFirebaseErrorMessage,
} from '../../infrastructures/firebase';
import { addErrorEventOnSentry } from '../../infrastructures/sentry';
import { useNetworker } from '../common/use-networker';

export const useAuthenticate = () => {
  const networker = useNetworker();
  const { setAuthStatus, setSnackbar } = useContext(UiContext);

  const signInWithEmail = async (mailAddress: string, password: string) => {
    try {
      const credential = await signInWithEmailAndPassword(
        FirebaseAuth,
        mailAddress,
        password,
      ).catch((error) => {
        const errorMessage = getFirebaseErrorMessage(error.code);
        setSnackbar(errorMessage);
      });

      if (!credential) {
        return { success: false };
      }

      const result = await credential.user.getIdTokenResult(true);

      // claims内のisAdminフラグがtrueでないとログインできない
      if (!result.claims.isAdmin) {
        await SignOutOnFirebase(FirebaseAuth);
        setSnackbar('このユーザーは管理者ではありません');
        return { success: false };
      }

      return { success: true };
    } catch (error) {
      addErrorEventOnSentry(error);
      throw new Error('user is not admin');
    }
  };

  const signOut = async () => {
    await networker(async () => {
      await SignOutOnFirebase(FirebaseAuth);

      setAuthStatus('UN_AUTHORIZED');
      setSnackbar(labels.message.signout);
    });
  };

  return { signInWithEmail, signOut };
};
