import { AGENCY_TYPE } from '../constants';
import { AgencyType, AgencyTypeSlug } from '../entities';

export const getAgencyType = (slug: AgencyTypeSlug): AgencyType | null => {
  const result = AGENCY_TYPE.find((item) => item.slug === slug);

  if (!result) {
    return null;
  }
  return result;
};
