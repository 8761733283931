import { useEffect, useState } from 'react';
import { onSnapshot, query, where } from 'firebase/firestore';
import { getUserPredictionCollection } from '../../infrastructures/firebase';
import { addErrorEventOnSentry } from '../../infrastructures/sentry';

export const usePrediction = (uid?: string) => {
  const [isFetching, setIsFetching] = useState(true);
  const [isPredicting, setIsPredicting] = useState(false);

  useEffect(() => {
    if (!uid) {
      return;
    }

    const ref = getUserPredictionCollection(uid);
    const q = query(ref, where('isProcessing', '==', true));

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());

        setIsPredicting(!!data.length);
        setIsFetching(false);
      },
      (error) => {
        addErrorEventOnSentry(error);
        setIsFetching(false);
      },
    );

    return unsubscribe;
  }, []);

  return { isFetching, isPredicting };
};
