import React, { FunctionComponent, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { AGENCY_TYPE, labels } from '../../../constants';
import { Agency, AgencyFormData } from '../../../entities';
import { useNavigate } from 'react-router-dom';
import { useAgencyEditTools } from '../../../hooks';

type AgencyCommonFormProps = {
  agency: Agency;
  method: 'add' | 'edit';
};

export const AgencyCommonForm: FunctionComponent<AgencyCommonFormProps> = ({
  agency,
  method = 'add',
}) => {
  const { addAgency, updateAgency } = useAgencyEditTools();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AgencyFormData>({
    defaultValues: {
      name: agency.name,
      area: agency.area,
      url: agency.url,
      inheritance: agency.inheritance,
      type: agency.type,
      score: agency.score,
      totalScore: agency.totalScore,
      createdAt: method === 'add' ? new Date() : agency.createdAt,
      updatedAt: new Date(),
    },
  });

  const onSubmit: SubmitHandler<AgencyFormData> = async (formData) => {
    // methodに応じて add か update を切り替え
    if (method === 'add') {
      await addAgency(formData);
    } else {
      await updateAgency(agency.id, formData);
    }
  };

  const navigate = useNavigate();

  const onClickBack = useCallback(() => {
    if (window.confirm(labels.message.confirmToGoBack)) {
      navigate(-1);
    }
  }, []);

  return (
    <Stack component='form' onSubmit={handleSubmit(onSubmit)} rowGap={3}>
      {/* 事務所の基本情報に関する項目設定 */}
      <Card>
        <CardHeader title={labels.data.agency.basicInfo} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name='name'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={labels.data.agency.name}
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message}
                  />
                )}
              />
              <Controller
                name='area'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={labels.data.agency.area}
                    error={Boolean(errors?.area)}
                    helperText={errors?.area?.message}
                  />
                )}
              />
              <Controller
                name='inheritance'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label={labels.data.agency.inheritance}
                    error={Boolean(errors?.inheritance)}
                    helperText={errors?.inheritance?.message}
                  >
                    <MenuItem value='コマーシャルモデル'>
                      コマーシャルモデル
                    </MenuItem>
                    <MenuItem value='ファッションモデル'>
                      ファッションモデル
                    </MenuItem>
                    <MenuItem value='タレント'>タレント</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* タレント事務所用の項目 */}
      <Card>
        <CardHeader
          title='タレント事務所用の項目'
          subheader='※ カテゴリーが「タレント」の場合に入力が必須です。'
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name='url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={labels.data.agency.url}
                    error={Boolean(errors?.url)}
                    helperText={errors?.url?.message}
                    placeholder='例）https://azukariagent.promodelstudio.com/'
                  />
                )}
              />
              <Controller
                name='type'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label={labels.data.agency.type}
                    error={Boolean(errors?.type)}
                    helperText={errors?.type?.message}
                  >
                    {AGENCY_TYPE.map((type) => (
                      <MenuItem key={type.slug} value={type.slug}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                name='totalScore'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={labels.data.agency.totalScore}
                    error={Boolean(errors?.totalScore)}
                    helperText={errors?.totalScore?.message}
                    placeholder='0~90の半角数字をご入力ください'
                  />
                )}
              />
              <Typography fontSize={12} color='text.secondary'>
                ※0~90の半角数字をご入力ください
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* レーダーチャートに関する項目設定 */}
      <Card>
        <CardHeader
          title='モデル事務所用の項目'
          subheader='※ カテゴリーが「タレント」の場合は全て0点のままで構いません。'
        />
        <Divider />
        <CardContent>
          {/* 身長 */}
          <Controller
            name='score.height'
            control={control}
            rules={{
              required: labels.form.errors.required,
              min: { value: 0, message: '0以上の数値を入力してください' },
              max: { value: 10, message: '10以下の数値を入力してください' },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={labels.data.scores.height}
                type='number'
                error={Boolean(errors?.score?.height)}
                helperText={errors?.score?.height?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>点</InputAdornment>
                  ),
                }}
              />
            )}
          />
          {/* 全身バランス */}
          <Controller
            name='score.balance'
            control={control}
            rules={{
              required: labels.form.errors.required,
              min: { value: 0, message: '0以上の数値を入力してください' },
              max: { value: 20, message: '20以下の数値を入力してください' },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={labels.data.scores.balance}
                type='number'
                error={Boolean(errors?.score?.balance)}
                helperText={errors?.score?.balance?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>点</InputAdornment>
                  ),
                }}
              />
            )}
          />
          {/* 脚の形 */}
          <Controller
            name='score.legShape'
            control={control}
            rules={{
              required: labels.form.errors.required,
              min: { value: 0, message: '0以上の数値を入力してください' },
              max: { value: 30, message: '30以下の数値を入力してください' },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={labels.data.scores.legShape}
                type='number'
                error={Boolean(errors?.score?.legShape)}
                helperText={errors?.score?.legShape?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>点</InputAdornment>
                  ),
                }}
              />
            )}
          />
          {/* 顔立ち */}
          <Controller
            name='score.features'
            control={control}
            rules={{
              required: labels.form.errors.required,
              min: { value: 0, message: '0以上の数値を入力してください' },
              max: { value: 10, message: '10以下の数値を入力してください' },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={labels.data.scores.features}
                type='number'
                error={Boolean(errors?.score?.features)}
                helperText={errors?.score?.features?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>点</InputAdornment>
                  ),
                }}
              />
            )}
          />
          {/* 肌質 */}
          <Controller
            name='score.skinQuality'
            control={control}
            rules={{
              required: labels.form.errors.required,
              min: { value: 0, message: '0以上の数値を入力してください' },
              max: { value: 10, message: '10以下の数値を入力してください' },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={labels.data.scores.skinQuality}
                type='number'
                error={Boolean(errors?.score?.skinQuality)}
                helperText={errors?.score?.skinQuality?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>点</InputAdornment>
                  ),
                }}
              />
            )}
          />
          {/* 年齢 */}
          <Controller
            name='score.age'
            control={control}
            rules={{
              required: labels.form.errors.required,
              min: { value: 0, message: '0以上の数値を入力してください' },
              max: { value: 10, message: '10以下の数値を入力してください' },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={labels.data.scores.age}
                type='number'
                error={Boolean(errors?.score?.age)}
                helperText={errors?.score?.age?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>点</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </CardContent>
      </Card>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Button variant='outlined' onClick={onClickBack}>
          {labels.form.actions.back}
        </Button>
        {isSubmitting ? (
          <Button variant='contained' disabled>
            {labels.form.actions.loading}
          </Button>
        ) : (
          <Button type='submit' variant='contained'>
            {labels.form.actions.save}
          </Button>
        )}
      </Box>
    </Stack>
  );
};
