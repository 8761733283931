import React from 'react';
import { Box } from '@mui/material';
import { AdminLayout } from '../../components/templates';
import { PageHeader } from '../../components/molecules';
import { labels } from '../../constants';
import { ModelCategoryCommonForm } from '../../components/features/model-category/ModelCategoryCommonForm';
import { createInitialModelCategory } from '../../entities';

export const ModelCategoryAddPage = () => {
  const modelCategory = createInitialModelCategory();

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.modelCategory.add} />
      <Box component='main'>
        <ModelCategoryCommonForm modelCategory={modelCategory} method='add' />
      </Box>
    </AdminLayout>
  );
};
