import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetextFormat(value: number) {
  return `${value} 歳`;
}

type UserAgeSliderProps = {
  value?: number;
  min: number;
  max: number;
};

export const UserAgeSlider: FC<UserAgeSliderProps> = ({ value, min, max }) => {
  return (
    <Box sx={{ width: 100 }}>
      <Slider
        value={value}
        valueLabelDisplay='on'
        valueLabelFormat={valuetextFormat}
        getAriaValueText={valuetextFormat}
        marks={[
          { value: min, label: `${min}歳` },
          { value: max, label: `${max}歳` },
        ]}
        size='small'
        min={min}
        max={max}
        disabled
      />
    </Box>
  );
};
