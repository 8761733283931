import { onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Report } from '../../entities';
import { getUserReportRef } from '../../infrastructures/firebase';
import { addErrorEventOnSentry } from '../../infrastructures/sentry';

export const useReport = (uid?: string, reportId?: string) => {
  const [isFetching, setIsFetching] = useState(true);
  const [report, setReport] = useState<Report | null>(null);

  useEffect(() => {
    if (!uid || !reportId) {
      return;
    }

    const unsubscribe = onSnapshot(
      getUserReportRef(uid, reportId),
      (snapshot) => {
        const data = snapshot.data();

        if (data) {
          setReport(data);
        }

        setIsFetching(false);
      },
      (error) => {
        addErrorEventOnSentry(error);
        setIsFetching(false);
      },
    );

    return unsubscribe;
  }, []);

  return { isFetching, report };
};
