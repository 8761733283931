import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { PublicUser } from '../../entities';
import { getPublicUserRef } from '../../infrastructures/firebase';
import { addErrorEventOnSentry } from '../../infrastructures/sentry';

export const usePublicUser = (uid?: string) => {
  const [isFetching, setIsFetching] = useState(true);
  const [publicUser, setPublicUser] = useState<PublicUser | null>(null);

  useEffect(() => {
    if (!uid) {
      return;
    }
    const ref = getPublicUserRef(uid);
    const unsubscribe = onSnapshot(
      ref,
      (snapshot) => {
        const data = snapshot.data();

        if (data) {
          setPublicUser(data);
        }

        setIsFetching(false);
      },
      (error) => {
        addErrorEventOnSentry(error);
        setIsFetching(false);
      },
    );

    return unsubscribe;
  }, []);

  return { isFetching, publicUser };
};
