import React, { FC } from 'react';
import { Card, CardHeader, Divider } from '@mui/material';
import { labels } from '../../../constants';
import { PropertyList } from '../../atoms';
import { ModelCategory } from '../../../entities';

interface ModelCategoryChartPropertyProps {
  modelCategory: ModelCategory;
}

export const ModelCategoryChartProperty: FC<
  ModelCategoryChartPropertyProps
> = ({ modelCategory }) => {
  return (
    <>
      {modelCategory.inheritance === 'ファッションモデル' && (
        <Card>
          <CardHeader title={labels.data.modelCategory.fashionModelScore} />
          <Divider />
          <PropertyList.Wrapper>
            <PropertyList.Item
              label={labels.data.scores.height}
              value={modelCategory.fashionModelScore.height}
            />
            <PropertyList.Item
              label={labels.data.scores.balance}
              value={modelCategory.fashionModelScore.balance}
            />
            <PropertyList.Item
              label={labels.data.scores.legShape}
              value={modelCategory.fashionModelScore.legShape}
            />
            <PropertyList.Item
              label={labels.data.scores.limbLength}
              value={modelCategory.fashionModelScore.limbLength}
            />
            <PropertyList.Item
              label={labels.data.scores.features}
              value={modelCategory.fashionModelScore.features}
            />
            <PropertyList.Item
              label={labels.data.scores.nose}
              value={modelCategory.fashionModelScore.nose}
            />
            <PropertyList.Item
              label={labels.data.scores.skinQuality}
              value={modelCategory.fashionModelScore.skinQuality}
            />
            <PropertyList.Item
              label={labels.data.scores.age}
              value={modelCategory.fashionModelScore.age}
            />
          </PropertyList.Wrapper>
        </Card>
      )}

      {modelCategory.inheritance === 'コマーシャルモデル' && (
        <Card>
          <CardHeader title={labels.data.modelCategory.commercialModelScore} />
          <Divider />
          <PropertyList.Wrapper>
            <PropertyList.Item
              label={labels.data.scores.features}
              value={modelCategory.commercialModelScore.features}
            />
            <PropertyList.Item
              label={labels.data.scores.age}
              value={modelCategory.commercialModelScore.age}
            />
            <PropertyList.Item
              label={labels.data.scores.balance}
              value={modelCategory.commercialModelScore.balance}
            />
            <PropertyList.Item
              label={labels.data.scores.legShape}
              value={modelCategory.commercialModelScore.legShape}
            />
            <PropertyList.Item
              label={labels.data.scores.smile}
              value={modelCategory.commercialModelScore.smile}
            />
            <PropertyList.Item
              label={labels.data.scores.height}
              value={modelCategory.commercialModelScore.height}
            />
            <PropertyList.Item
              label={labels.data.scores.nose}
              value={modelCategory.commercialModelScore.nose}
            />
            <PropertyList.Item
              label={labels.data.scores.skinQuality}
              value={modelCategory.commercialModelScore.skinQuality}
            />
          </PropertyList.Wrapper>
        </Card>
      )}
    </>
  );
};
