import React, { FunctionComponent } from 'react';
import type { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Agency, AgencyTypeSlug } from '../../../entities';
import { createAgencyDetailPath, labels } from '../../../constants';
import { formatDateTime } from '../../../utils/format-date';
import { getAgencyType } from '../../../domains/agency-type';

interface AgencyListTableProps {
  items: Agency[];
  itemsCount: number;
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  page: number;
  rowsPerPage: number;
}

export const AgencyListTable: FunctionComponent<AgencyListTableProps> = (
  props,
) => {
  const { items, itemsCount, onPageChange, page, rowsPerPage, ...other } =
    props;

  return (
    <div {...other}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{labels.data.agency.name}</TableCell>
            <TableCell>{labels.data.agency.inheritance}</TableCell>
            <TableCell>{labels.data.agency.type}</TableCell>
            <TableCell>{labels.data.agency.createdAt}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((agency) => {
            const agencyType = getAgencyType(agency.type as AgencyTypeSlug);

            return (
              <TableRow hover key={agency.id}>
                <TableCell>
                  <Link to={createAgencyDetailPath(agency.id)}>
                    {agency.name}
                  </Link>
                </TableCell>
                <TableCell>{agency.inheritance}</TableCell>
                <TableCell>{agencyType?.name}</TableCell>
                <TableCell>{formatDateTime(agency.createdAt)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={itemsCount}
        onPageChange={onPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10]}
      />
    </div>
  );
};
