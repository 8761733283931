import React, { useState, SyntheticEvent } from 'react';
import { Box, Divider, Grid, Link, Tab, Tabs, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AdminLayout } from '../../components/templates';
import { PageHeader } from '../../components/molecules';
import { labels } from '../../constants';
import { PATH } from '../../constants';
import { UserBasicProperty } from '../../components/features/user/UserBasicProperty';
import { UserLabelsProperty } from '../../components/features/user/UserLabelsProperty';
import {
  useReports,
  usePublicUser,
  useUserLog,
  useQuery,
  usePrediction,
} from '../../hooks';
import { UserImageProperty } from '../../components/features/user/UserImageProperty';
import { UserLogTable } from '../../components/features/user/UserLogTable';
import { UserMissingPropertyAlert } from '../../components/features/user/UserMissingPropertyAlert';
import { UserTopMenu } from '../../components/features/user/UserTopMenu';
import { UserReportsTable } from '../../components/features/user/UserReportsTable';

const tabs = [
  { label: labels.data.user.basicInfo, value: 'details' },
  { label: labels.data.user.images.title, value: 'images' },
  { label: labels.data.user.labels, value: 'label' },
  { label: labels.data.user.reports, value: 'reports' },
  { label: labels.data.user.logs, value: 'logs' },
];

export const UserDetailPage = () => {
  const { uid } = useParams<{ uid: string }>();

  if (!uid) {
    return <p>uidが取得できませんでした</p>;
  }

  const { publicUser } = usePublicUser(uid);
  const { logs } = useUserLog(uid);
  const { reports } = useReports(uid);
  const { isPredicting } = usePrediction(uid);

  // URLからquery paramsを取得
  // tab=xxxが設定されていればそれを適用するが何も取得できなければ
  // "details"タブをデフォルトで表示する
  const query = useQuery();
  const [currentTab, setCurrentTab] = useState<string>(
    query.get('tab') || 'details',
  );

  const handleTabsChange = (event: SyntheticEvent, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.users.detail} />

      {publicUser && (
        <Box component='main'>
          {/* 戻るボタン */}
          <Box sx={{ mb: 4, display: 'inline-block' }}>
            <Link
              to={PATH.USER_LIST}
              color='textPrimary'
              component={RouterLink}
              sx={{ alignItems: 'center', display: 'flex' }}
            >
              <ArrowBackIcon fontSize='small' sx={{ mr: 1 }} />
              <Typography variant='subtitle2'>
                {labels.pages.users.list}
              </Typography>
            </Link>
          </Box>

          {/* 名前とメニューボタン */}
          <UserTopMenu publicUser={publicUser} />

          {/* 足りないプロパティのアラートを表示する */}
          <UserMissingPropertyAlert
            publicUser={publicUser}
            isPredicting={isPredicting}
          />

          {/* タブバー */}
          <Tabs
            indicatorColor='primary'
            onChange={handleTabsChange}
            scrollButtons='auto'
            textColor='primary'
            value={currentTab}
            variant='scrollable'
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />

          {/* タブコンテンツ */}
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {currentTab === 'details' && (
                  <UserBasicProperty publicUser={publicUser} />
                )}
                {currentTab === 'images' && (
                  <UserImageProperty publicUser={publicUser} />
                )}
                {currentTab === 'label' && (
                  <UserLabelsProperty publicUser={publicUser} />
                )}
                {currentTab === 'reports' && (
                  <UserReportsTable reports={reports} />
                )}
                {currentTab === 'logs' && <UserLogTable logs={logs} />}
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </AdminLayout>
  );
};
