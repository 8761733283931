import React, { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { ModelCategory } from '../../../entities';
import { UserRaderChart } from '../reports/UserRaderChart';
import { labels } from '../../../constants';

type ModalOtherModelCategoryProps = {
  modelCategories: ModelCategory[];
  isOpen: boolean;
  setClose: () => void;
  isDebug: boolean;
};

export const ModalOtherModelCategory: FunctionComponent<
  ModalOtherModelCategoryProps
> = ({ modelCategories, isOpen, setClose, isDebug }) => {
  return (
    <Dialog open={isOpen} onClose={setClose} maxWidth='lg'>
      <DialogTitle>その他のカテゴリー</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          {modelCategories.map((category) => {
            const chartLabels =
              category.inheritance === 'ファッションモデル'
                ? labels.charts.labels.FM
                : labels.charts.labels.CM;

            const data =
              category.inheritance === 'ファッションモデル'
                ? [
                    category.fashionModelScore.height,
                    category.fashionModelScore.balance / 2,
                    category.fashionModelScore.legShape / 3,
                    category.fashionModelScore.limbLength / 2,
                    category.fashionModelScore.features,
                    category.fashionModelScore.nose / 2,
                    category.fashionModelScore.skinQuality,
                    category.fashionModelScore.age,
                  ]
                : [
                    category.commercialModelScore.features,
                    category.commercialModelScore.balance / 2,
                    category.commercialModelScore.legShape / 3,
                    category.commercialModelScore.smile / 2,
                    category.commercialModelScore.height,
                    category.commercialModelScore.nose / 2,
                    category.commercialModelScore.skinQuality,
                    category.commercialModelScore.age,
                  ];

            const color =
              category.inheritance === 'ファッションモデル'
                ? 'secondary'
                : 'primary';

            return (
              <Grid item xs={6} sm={4} key={category.id}>
                <Typography fontWeight='bold' textAlign='center' fontSize={13}>
                  {category.name}
                </Typography>
                <UserRaderChart
                  labels={chartLabels}
                  data={data}
                  color={color}
                  labelDisplay={isDebug}
                />
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={setClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
};
