import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export const Loading = () => (
  <Backdrop
    open={true}
    sx={{ color: 'common.white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  >
    <CircularProgress color='inherit' />
  </Backdrop>
);
