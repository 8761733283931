import React from 'react';
import { Box } from '@mui/material';
import { AdminLayout } from '../../components/templates';
import { PageHeader } from '../../components/molecules';
import { labels } from '../../constants';
import { AgencyCommonForm } from '../../components/features/agencies/AgencyCommonForm';
import { createInitialAgency } from '../../entities';

export const AgencyAddPage = () => {
  const agency = createInitialAgency();

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.agencies.add} />
      <Box component='main'>
        <AgencyCommonForm agency={agency} method='add' />
      </Box>
    </AdminLayout>
  );
};
