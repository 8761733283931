import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { PATH } from '../constants';
import { AuthStatus, UiContext } from '../contexts';
import {
  Loading,
  NotFoundPage,
  SignInPage,
  TopPage,
  AgencyAddPage,
  AgencyDetailPage,
  AgencyEditPage,
  AgencyListPage,
  ModelCategoryAddPage,
  ModelCategoryDetailPage,
  ModelCategoryEditPage,
  ModelCategoryListPage,
  TipsEditPage,
  UserAddPage,
  UserDetailPage,
  UserListPage,
  UserReportPage,
  UserEditPage,
} from '../pages';

const UnAuthRoutes = () => (
  <Routes>
    <Route path={PATH.SIGN_IN} element={<SignInPage />} />
    <Route path='*' element={<Navigate to={PATH.SIGN_IN} />} />
  </Routes>
);

function AuthUserRoutes() {
  return (
    <Routes>
      <Route path={PATH.HOME} element={<TopPage />} />
      <Route path={PATH.AGENCY_ADD} element={<AgencyAddPage />} />
      <Route path={PATH.AGENCY_DETAIL} element={<AgencyDetailPage />} />
      <Route path={PATH.AGENCY_EDIT} element={<AgencyEditPage />} />
      <Route path={PATH.AGENCY_LIST} element={<AgencyListPage />} />
      <Route
        path={PATH.MODEL_CATEGORY_ADD}
        element={<ModelCategoryAddPage />}
      />
      <Route
        path={PATH.MODEL_CATEGORY_EDIT}
        element={<ModelCategoryEditPage />}
      />
      <Route
        path={PATH.MODEL_CATEGORY_DETAIL}
        element={<ModelCategoryDetailPage />}
      />
      <Route
        path={PATH.MODEL_CATEGORY_LIST}
        element={<ModelCategoryListPage />}
      />
      <Route path={PATH.TIPS_EDIT} element={<TipsEditPage />} />
      <Route path={PATH.USER_ADD} element={<UserAddPage />} />
      <Route path={PATH.USER_EDIT} element={<UserEditPage />} />
      <Route path={PATH.USER_DETAIL} element={<UserDetailPage />} />
      <Route path={PATH.USER_LIST} element={<UserListPage />} />
      <Route path={PATH.USER_REPORT} element={<UserReportPage />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
}

const switchingAuthStatus = (authStatus: AuthStatus) => {
  switch (authStatus) {
    case 'UN_AUTHORIZED':
      return UnAuthRoutes();
    case 'AUTHORIZED':
      return AuthUserRoutes();
    default:
      return <Route path='*' element={<Loading />} />;
  }
};

const MainNavigators = () => {
  const { authStatus } = useContext(UiContext);

  return (
    <BrowserRouter>
      {authStatus !== 'LOADING' ? (
        switchingAuthStatus(authStatus)
      ) : (
        <Routes>
          <Route path='*' element={<Loading />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};
export default MainNavigators;
