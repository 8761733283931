import { PublicUser } from '../entities';

const isExistImages = (user: PublicUser | null): boolean => {
  return (
    user !== null &&
    !!user.bustupImgUrl &&
    !!user.fullbodyImgUrl &&
    !!user.sideImgUrl &&
    !!user.smileImgUrl
  );
};

const isPublishable = (user: PublicUser | null): boolean => {
  return (
    user !== null &&
    !!user.birthday &&
    !!user.height &&
    !!user.bust &&
    !!user.waist &&
    !!user.hip &&
    !!user.headsTall &&
    !!user.legLength &&
    !!user.legShape &&
    !!user.legBalance &&
    !!user.kneeLength &&
    !!user.handLength &&
    !!user.faceType &&
    !!user.eyeType &&
    !!user.eyeShape &&
    !!user.ridgeOfNose &&
    !!user.noseSize &&
    !!user.lipThickness &&
    !!user.mouthSize &&
    !!user.noseRatio &&
    !!user.noseShapeFront &&
    !!user.noseShapeSide &&
    !!user.skinType &&
    !!user.mouthShape &&
    !!user.dentition &&
    !!user.malocclusion &&
    !!user.pelvicTension &&
    !!user.eyeFeature &&
    !!user.asymmetricalFace &&
    !!user.noChin
  );
};

export const PublicUserDomain = {
  isExistImages,
  isPublishable,
};
