import { getDownloadURL, uploadBytes } from 'firebase/storage';
import { labels } from '../constants';
import { Image } from '../entities';
import { getStorageRef } from '../infrastructures/firebase';
import { addErrorEventOnSentry } from '../infrastructures/sentry';

const add = async (fileName: string, image: Image) => {
  try {
    const ref = getStorageRef(fileName);
    await uploadBytes(ref, image);

    const url = await getDownloadURL(ref);
    return url;
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.uploadFailure);
  }
};

export const StorageRepository = {
  add,
};
