import React, { useState, MouseEvent } from 'react';
import { Card, CardHeader, CircularProgress, Divider } from '@mui/material';
import { PageHeader } from '../../components/molecules';
import { AdminLayout } from '../../components/templates';
import { labels } from '../../constants';
import { ModelCategoryListTable } from '../../components/features/model-category/ModelCategoryListTable';
import { applyPagination } from '../../utils/apply-pagination';
import { useFetchModelCategories } from '../../hooks';

const rowsPerPage = 20;

export const ModelCategoryListPage = () => {
  const { isFetching, modelCategories } = useFetchModelCategories();
  const [page, setPage] = useState<number>(0);

  const paginatedModelCategories = applyPagination(
    modelCategories,
    page,
    rowsPerPage,
  );

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.modelCategory.list} />
      <Card>
        <CardHeader title={labels.pages.modelCategory.list} />
        <Divider />

        {/* 読み込み中はローディングアイコンを表示 */}
        {isFetching ? (
          <CircularProgress />
        ) : (
          <ModelCategoryListTable
            items={paginatedModelCategories}
            itemsCount={paginatedModelCategories.length}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        )}
      </Card>
    </AdminLayout>
  );
};
