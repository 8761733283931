import React, { FunctionComponent, ReactNode, useReducer } from 'react';
import {
  createInitialNetworkState,
  NetworkContext,
  networkReducer,
} from '../contexts';

type NetworkManagerProps = {
  children: ReactNode;
};

export const NetworkManager: FunctionComponent<NetworkManagerProps> = ({
  children,
}) => {
  const [networkState, dispatchNetworkActions] = useReducer(
    networkReducer,
    createInitialNetworkState(),
  );
  return (
    <NetworkContext.Provider value={{ networkState, dispatchNetworkActions }}>
      {children}
    </NetworkContext.Provider>
  );
};
