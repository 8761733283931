import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { App } from './app';

Sentry.init({
  dsn: 'https://501dbda1e5984447b6bad3573a553fcd@o469785.ingest.sentry.io/6508836',
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_VERSION,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.8,
});

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
} else {
  throw new Error('#root が見つかりませんでした');
}
