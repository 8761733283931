import { useCallback, useContext } from 'react';
import { labels } from '../../constants';
import { UiContext } from '../../contexts';
import { ModelPhotoFormData } from '../../entities';
import { ModelPhotoRepository } from '../../repositories';
import { useNetworker } from '../common/use-networker';

export const usePhotoEditTool = () => {
  const { setSnackbar } = useContext(UiContext);
  const networker = useNetworker();

  const addModelPhoto = useCallback(async (formData: ModelPhotoFormData) => {
    await networker(async () => {
      // add object to firebase
      await ModelPhotoRepository.add(formData);
      // display success message
      setSnackbar(labels.message.addSuccess);
    });
  }, []);

  const deleteModelPhoto = useCallback(async (photoId: string) => {
    await networker(async () => {
      // remove data from firebase
      await ModelPhotoRepository.remove(photoId);
      // display success message
      setSnackbar(labels.message.deleteSuccess);
    });
  }, []);

  return { addModelPhoto, deleteModelPhoto };
};
