import React, { useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { AdminLayout } from '../../components/templates';
import { PageHeader } from '../../components/molecules';
import { createModelCategoryEditPath, labels } from '../../constants';
import { PATH } from '../../constants';
import { ModelCategoryBasicProperty } from '../../components/features/model-category/ModelCategoryBasicProperty';
import { ModelCategoryChartProperty } from '../../components/features/model-category/ModelCategoryChartProperty';
import { useModelCategory, usePhotos } from '../../hooks';
import { ModelPhotoProperty } from '../../components/features/model-photo/ModelPhotoProperty';

type tabType = 'basic' | 'chart' | 'photo';

const tabs: {
  label: string;
  value: tabType;
}[] = [
  { label: labels.data.modelCategory.basicInfo, value: 'basic' },
  { label: labels.data.modelCategory.raderChart, value: 'chart' },
  { label: labels.data.modelCategory.photo, value: 'photo' },
];

export const ModelCategoryDetailPage = () => {
  const [currentTab, setCurrentTab] = useState<string>('basic');
  const { categoryId } = useParams<{ categoryId: string }>();

  const handleTabsChange = (event: SyntheticEvent, value: string): void => {
    setCurrentTab(value);
  };

  if (!categoryId) {
    return <p>IDを取得できませんでした</p>;
  }

  const { isFetching, modelCategory } = useModelCategory(categoryId);

  const { modelPhotos } = usePhotos({ categoryId });

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.modelCategory.detail} />

      {/* 読み込み中はローディングアイコンを表示 */}
      {isFetching || !modelCategory ? (
        <CircularProgress />
      ) : (
        <Box component='main' sx={{ flexGrow: 1 }}>
          <Box sx={{ mb: 4, display: 'inline-block' }}>
            <Link
              to={PATH.MODEL_CATEGORY_LIST}
              color='textPrimary'
              component={RouterLink}
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <ArrowBackIcon fontSize='small' sx={{ mr: 1 }} />
              <Typography variant='subtitle2'>
                {labels.pages.modelCategory.list}
              </Typography>
            </Link>
          </Box>
          <Grid container justifyContent='space-between' spacing={3}>
            <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography variant='h4'>{modelCategory.name}</Typography>
            </Grid>
            <Grid item sx={{ m: -1 }}>
              <Button
                component={RouterLink}
                startIcon={<EditIcon fontSize='small' />}
                sx={{ m: 1 }}
                variant='outlined'
                to={createModelCategoryEditPath(categoryId)}
              >
                {labels.form.actions.edit}
              </Button>
            </Grid>
          </Grid>
          <Tabs
            indicatorColor='primary'
            onChange={handleTabsChange}
            scrollButtons='auto'
            sx={{ mt: 3 }}
            textColor='primary'
            value={currentTab}
            variant='scrollable'
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === 'basic' && (
              <ModelCategoryBasicProperty modelCategory={modelCategory} />
            )}
            {currentTab === 'chart' && (
              <ModelCategoryChartProperty modelCategory={modelCategory} />
            )}
            {currentTab === 'photo' && (
              <ModelPhotoProperty
                photos={modelPhotos}
                modelCategory={modelCategory}
              />
            )}
          </Box>
        </Box>
      )}
    </AdminLayout>
  );
};
