export const PATH = {
  HOME: '/',
  TIPS_EDIT: '/tips/edit',
  USER_LIST: '/users',
  USER_ADD: '/users/add',
  USER_DETAIL: '/users/:uid',
  USER_EDIT: '/users/:uid/edit',
  USER_REPORT: '/users/:uid/reports/:reportId',
  AGENCY_LIST: '/agencies',
  AGENCY_ADD: '/agencies/add',
  AGENCY_DETAIL: '/agencies/:agencyId',
  AGENCY_EDIT: '/agencies/:agencyId/edit',
  MODEL_CATEGORY_LIST: '/model-categories',
  MODEL_CATEGORY_ADD: '/model-categories/add',
  MODEL_CATEGORY_DETAIL: '/model-categories/:categoryId',
  MODEL_CATEGORY_EDIT: '/model-categories/:categoryId/edit',
  MODEL_PHOTO_LIST: '/model-photo',
  SIGN_IN: '/signin',
};

export const createUserDetailPath = (uid: string) => {
  return PATH.USER_DETAIL.replace(':uid', uid);
};

export const createUserEditPath = (uid: string) => {
  return PATH.USER_EDIT.replace(':uid', uid);
};

export const createUserReportPath = (uid: string, reportId: string) => {
  return PATH.USER_REPORT.replace(':uid', uid).replace(':reportId', reportId);
};

export const createAgencyDetailPath = (agencyId: string) => {
  return PATH.AGENCY_DETAIL.replace(':agencyId', agencyId);
};

export const createAgencyEditPath = (agencyId: string) => {
  return PATH.AGENCY_EDIT.replace(':agencyId', agencyId);
};

export const createModelCategoryDetailPath = (categoryId: string) => {
  return PATH.MODEL_CATEGORY_DETAIL.replace(':categoryId', categoryId);
};

export const createModelCategoryEditPath = (categoryId: string) => {
  return PATH.MODEL_CATEGORY_EDIT.replace(':categoryId', categoryId);
};
