import React, { FunctionComponent } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

type AddButtonProps = {
  label: string;
  to: string;
};

export const AddButton: FunctionComponent<AddButtonProps> = ({ label, to }) => {
  return (
    <Button
      startIcon={<AddIcon fontSize='small' />}
      variant='contained'
      component={Link}
      to={to}
    >
      {label}
    </Button>
  );
};
