import React, { FunctionComponent } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { UserLog } from '../../../entities';
import { labels } from '../../../constants';
import { formatDateTime } from '../../../utils/format-date';
import { Link } from 'react-router-dom';

interface InformationListTableProps {
  logs: UserLog[];
}

export const InformationListTable: FunctionComponent<
  InformationListTableProps
> = ({ logs }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{labels.data.news.createdAt}</TableCell>
          <TableCell>{labels.data.news.body}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {logs.map((log, i) => {
          return (
            <TableRow hover key={i}>
              <TableCell>{formatDateTime(log.createdAt)}</TableCell>
              <TableCell>
                {log.url ? (
                  <Link to={log.url}>{log.title}</Link>
                ) : (
                  <>{log.title}</>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
