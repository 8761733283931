import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  type DocumentData,
} from 'firebase/firestore';
import { CommercialModelScore, FashionModelScore } from './Score';

export type ModelCategory = {
  id: string;
  name: string;
  inheritance: string;
  description: string;
  fashionModelScore: FashionModelScore;
  commercialModelScore: CommercialModelScore;
  createdAt: Date;
  updatedAt: Date;
};

export type ModelCategoryFormData = Omit<ModelCategory, 'id'>;

// 初期のフォームインスタンス用
export const createInitialModelCategory = (): ModelCategory => {
  return {
    id: '', // firebase保存時に一意のIDを付与する
    name: '',
    inheritance: '',
    description: '',
    fashionModelScore: {
      height: 0,
      balance: 0,
      legShape: 0,
      limbLength: 0,
      features: 0,
      nose: 0,
      skinQuality: 0,
      age: 0,
    },
    commercialModelScore: {
      features: 0,
      age: 0,
      balance: 0,
      legShape: 0,
      smile: 0,
      height: 0,
      nose: 0,
      skinQuality: 0,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  };
};

/**
 * Firestore のドキュメントと ModelCategory オブジェクトの型変換を行います。
 */
export const modelCategoryConverter: FirestoreDataConverter<ModelCategory> = {
  /**
   * ModelCategory オブジェクトを Firestore ドキュメントデータへ変換します。
   */
  toFirestore(modelCategory: ModelCategory): DocumentData {
    return {
      id: modelCategory.id,
      name: modelCategory.name,
      inheritance: modelCategory.inheritance,
      description: modelCategory.description,
      fashionModelScore: {
        height: +modelCategory.fashionModelScore.height,
        balance: +modelCategory.fashionModelScore.balance,
        legShape: +modelCategory.fashionModelScore.legShape,
        limbLength: +modelCategory.fashionModelScore.limbLength,
        features: +modelCategory.fashionModelScore.features,
        nose: +modelCategory.fashionModelScore.nose,
        skinQuality: +modelCategory.fashionModelScore.skinQuality,
        age: +modelCategory.fashionModelScore.age,
      },
      commercialModelScore: {
        features: +modelCategory.commercialModelScore.features,
        age: +modelCategory.commercialModelScore.age,
        balance: +modelCategory.commercialModelScore.balance,
        legShape: +modelCategory.commercialModelScore.legShape,
        smile: +modelCategory.commercialModelScore.smile,
        height: +modelCategory.commercialModelScore.height,
        nose: +modelCategory.commercialModelScore.nose,
        skinQuality: +modelCategory.commercialModelScore.skinQuality,
      },
      createdAt: modelCategory.createdAt,
      updatedAt: modelCategory.updatedAt,
    };
  },

  /**
   * Firestore ドキュメントデータを ModelCategory オブジェクトへ変換します。
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): ModelCategory {
    const data = snapshot.data(options);

    return {
      id: data.id,
      name: data.name,
      inheritance: data.inheritance,
      description: data.description,
      fashionModelScore: data.fashionModelScore,
      commercialModelScore: data.commercialModelScore,
      createdAt: data.createdAt.toDate(),
      updatedAt: data.updatedAt.toDate(),
    };
  },
};
