import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  type DocumentData,
} from 'firebase/firestore';

export type ModelPhoto = {
  id: string;
  url: string;
  createdAt: Date;
  agencyId?: string;
  categoryId?: string;
};

export type ModelPhotoFormData = {
  agencyId?: string;
  categoryId?: string;
  image?: File;
};

/**
 * Firestore のドキュメントと ModelPhoto オブジェクトの型変換を行います。
 */
export const modelPhotoConverter: FirestoreDataConverter<ModelPhoto> = {
  /**
   * ModelPhoto オブジェクトを Firestore ドキュメントデータへ変換します。
   */
  toFirestore(photo: ModelPhoto): DocumentData {
    return {
      id: photo.id,
      url: photo.url,
      createdAt: photo.createdAt,
      ...(photo?.agencyId && { agencyId: photo.agencyId }),
      ...(photo?.categoryId && {
        categoryId: photo.categoryId,
      }),
    };
  },

  /**
   * Firestore ドキュメントデータを ModelPhoto オブジェクトへ変換します。
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): ModelPhoto {
    const data = snapshot.data(options);

    return {
      id: data.id,
      url: data.url,
      createdAt: data.createdAt.toDate(),
      agencyId: data.agencyId,
      categoryId: data.categoryId,
    };
  },
};
