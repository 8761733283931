import React, { FunctionComponent, useContext, useMemo } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { NetworkContext } from '../../contexts';

export const NetworkProgress: FunctionComponent = () => {
  const { networkState } = useContext(NetworkContext);
  const isCommunicating = useMemo(() => 0 < networkState, [networkState]);

  if (!isCommunicating) {
    return null;
  }

  return <LinearProgress color='warning' />;
};
