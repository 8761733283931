import { useEffect, useState } from 'react';
import { limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import { UserLog } from '../../entities';
import { getUserLogCollectionGroup } from '../../infrastructures/firebase';
import { addErrorEventOnSentry } from '../../infrastructures/sentry';

export const useAllUserLogs = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [logs, setLogs] = useState<UserLog[]>([]);

  useEffect(() => {
    const ref = getUserLogCollectionGroup();
    const q = query(ref, orderBy('createdAt', 'desc'), limit(10));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());

        setLogs(data);
        setIsFetching(false);
      },
      (error) => {
        addErrorEventOnSentry(error);
        setIsFetching(false);
      },
    );
    return () => unsubscribe();
  }, []);

  return { isFetching, logs };
};
