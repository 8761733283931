import { deleteDoc, setDoc } from 'firebase/firestore';
import { labels } from '../constants';
import { getAgencyType } from '../domains/agency-type';
import { AgencyFormData, AgencyTypeSlug } from '../entities';
import { getAgencyRef } from '../infrastructures/firebase';
import { addErrorEventOnSentry } from '../infrastructures/sentry';
import { createUUID } from '../utils/create-unique-id';

/*
 * 一意のIDをクライアント側で作成しフォームからのPOSTデータに付与しつつ、
 * Firestore上のドキュメントIDにもしつつ、保存する
 */
const add = async (formData: AgencyFormData) => {
  try {
    const id = createUUID();
    const agencyRef = getAgencyRef(id);
    const formDataWithNewID = { ...formData, id };

    // MEMO: フォームデータ内のtypeを元に受け入れ年齢と身長を取得したい
    const agencyType = getAgencyType(formData.type as AgencyTypeSlug);

    const fullData = {
      ...formDataWithNewID,
      ...(agencyType?.maxAge && { maxAge: agencyType.maxAge }),
      ...(agencyType?.minAge && { minAge: agencyType.minAge }),
      ...(agencyType?.maxHeight && { maxHeight: agencyType.maxHeight }),
      ...(agencyType?.minHeight && { minHeight: agencyType.minHeight }),
    };

    await setDoc(agencyRef, fullData);
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.addFailure);
  }
};

const set = async (agencyId: string, formData: AgencyFormData) => {
  try {
    const id = agencyId;
    const agencyRef = getAgencyRef(id);
    const formDataWithNewID = { ...formData, id };

    // MEMO: フォームデータ内のtypeを元に受け入れ年齢と身長を取得したい
    const agencyType = getAgencyType(formData.type as AgencyTypeSlug);

    const fullData = {
      ...formDataWithNewID,
      ...(agencyType?.maxAge && { maxAge: agencyType.maxAge }),
      ...(agencyType?.minAge && { minAge: agencyType.minAge }),
      ...(agencyType?.maxHeight && { maxHeight: agencyType.maxHeight }),
      ...(agencyType?.minHeight && { minHeight: agencyType.minHeight }),
    };

    await setDoc(agencyRef, fullData);
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.updateFailure);
  }
};

const remove = async (agencyId: string) => {
  try {
    const agencyRef = getAgencyRef(agencyId);

    await deleteDoc(agencyRef);
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.deleteFailure);
  }
};

export const AgencyRepository = {
  add,
  set,
  remove,
};
