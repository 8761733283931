import React from 'react';

export function createInitialNetworkState(): number {
  return 0;
}

type State = ReturnType<typeof createInitialNetworkState>;

interface Action {
  type: 'begin' | 'end';
}

export function networkReducer(state: State, action: Action): number {
  switch (action.type) {
    case 'begin':
      return state + 1;
    case 'end':
      return state - 1;
    default:
      return state;
  }
}

type NetworkContextType = {
  networkState: number;
  dispatchNetworkActions: React.Dispatch<Action>;
};

export const NetworkContext = React.createContext<NetworkContextType>({
  networkState: createInitialNetworkState(),
  dispatchNetworkActions: () => {},
});
