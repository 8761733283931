import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  type DocumentData,
} from 'firebase/firestore';

// 機械学習モデルによるラベルの推論の状況を保持するドキュメント
export type Prediction = {
  predictId: string;
  isProcessing: boolean;
  isError: boolean;
  errorCount: number;
  createdAt: Date;
  updatedAt: Date;
};

/**
 * Firestore のドキュメントと Prediction オブジェクトの型変換を行います。
 */
export const predictionConverter: FirestoreDataConverter<Prediction> = {
  /**
   * Prediction オブジェクトを Firestore ドキュメントデータへ変換します。
   */
  toFirestore(prediction: Prediction): DocumentData {
    return {
      predictId: prediction.predictId,
      isProcessing: prediction.isProcessing,
      isError: prediction.isError,
      errorCount: prediction.errorCount,
      createdAt: prediction.createdAt,
      updatedAt: prediction.updatedAt,
    };
  },

  /**
   * Firestore ドキュメントデータを Prediction オブジェクトへ変換します。
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Prediction {
    const data = snapshot.data(options);

    return {
      predictId: data.predictId,
      isProcessing: data.isProcessing,
      isError: data.isError,
      errorCount: data.errorCount,
      createdAt: data.createdAt.toDate(),
      updatedAt: data.updatedAt.toDate(),
    };
  },
};
