import { useCallback, useContext } from 'react';
import { labels } from '../../constants';
import { UiContext } from '../../contexts';
import { ReportFormData } from '../../entities';
import { ReportRepository } from '../../repositories';
import { useNetworker } from '../common/use-networker';

export const useReportEditTools = () => {
  const { setSnackbar } = useContext(UiContext);
  const networker = useNetworker();

  const createReport = useCallback(
    async (uid: string, formData: ReportFormData) => {
      await networker(async () => {
        // // add object to firebase
        await ReportRepository.add(uid, formData);

        // display success message
        setSnackbar(labels.message.createReportIsProcessing);
      });
    },
    [],
  );

  return { createReport };
};
