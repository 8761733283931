import { useContext } from 'react';
import { NetworkContext, UiContext } from '../../contexts';

/*
 * 通信を伴う場合、Reactコンポーネント以下のように記述する
 *
 * const networker = useNetworker();
 * const onPress = useCallback(async () => {
 *   await networker(async () => {
 *     await fetch('https://example.com');
 *   })
 * }, [navigate, networker, setTodos, mailAddress.value, password.
 * value]);
 */

type Task = () => Promise<void>;
export function useNetworker(): (task: Task) => Promise<void> {
  const { dispatchNetworkActions } = useContext(NetworkContext);
  const { setError } = useContext(UiContext);

  return async (task: Task) => {
    try {
      dispatchNetworkActions({ type: 'begin' });
      console.log('networker start.');
      await task();
      setError(null);
    } catch (error) {
      setError(error as Error);
    } finally {
      dispatchNetworkActions({ type: 'end' });
      console.log('networker end.');
    }
  };
}
