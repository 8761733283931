import { deleteDoc, setDoc } from 'firebase/firestore';
import { labels } from '../constants';
import { ModelPhotoFormData } from '../entities';
import { getModelPhotoRef } from '../infrastructures/firebase';
import { addErrorEventOnSentry } from '../infrastructures/sentry';
import { createUniqueFilename } from '../utils/create-unique-filename';
import { createUUID } from '../utils/create-unique-id';
import { StorageRepository } from './storage';

/*
 * 一意のIDをクライアント側で作成しフォームからのPOSTデータに付与しつつ、
 * Firestore上のドキュメントIDにもしつつ、保存する
 */
const add = async (formData: ModelPhotoFormData) => {
  // フォーム内にimageがセットされていないユーザーエラーを起こす
  if (!formData.image) {
    throw new Error('画像がセットされていません');
  }

  try {
    // MEMO: 画像にユニークなファイル名を付与してアップロード
    const image = formData.image;
    const fileName = createUniqueFilename(image);
    const url = await StorageRepository.add(
      `photos/modelCategory/${fileName}`,
      image,
    );

    const id = createUUID();
    const ref = getModelPhotoRef(id);

    const { agencyId, categoryId } = formData;
    const formDataWithNewID = {
      id,
      url,
      createdAt: new Date(),
      ...(agencyId && { agencyId: agencyId }),
      ...(categoryId && { categoryId: categoryId }),
    };

    await setDoc(ref, formDataWithNewID);
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.addFailure);
  }
};

const remove = async (photoId: string) => {
  try {
    const ref = getModelPhotoRef(photoId);

    // firestoreドキュメントの削除のみ
    await deleteDoc(ref);

    // TODO: storage上の画像ファイルの削除はfunctionsでしか行えない
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.deleteFailure);
  }
};

export const ModelPhotoRepository = {
  add,
  remove,
};
