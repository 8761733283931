import { useCallback, useContext } from 'react';
import { labels } from '../../constants';
import { UiContext } from '../../contexts';
import { LabelFormData } from '../../entities';
import { LabelRepository } from '../../repositories';
import { useNetworker } from '../common/use-networker';

export const useLabelEditTool = () => {
  const { setSnackbar } = useContext(UiContext);
  const networker = useNetworker();

  const addLabel = useCallback(async (uid: string, formData: LabelFormData) => {
    await networker(async () => {
      // add object to firebase
      await LabelRepository.add(uid, formData);

      // display success message
      setSnackbar(labels.message.updateSuccess);
    });
  }, []);

  return { addLabel };
};
