import { setDoc } from 'firebase/firestore';
import { useCallback, useContext } from 'react';
import { labels } from '../../constants';
import { UiContext } from '../../contexts';
import { Tips } from '../../entities';
import { getTipsRef } from '../../infrastructures/firebase';
import { useNetworker } from '../common/use-networker';

export const useTipsEditTools = () => {
  const { setSnackbar } = useContext(UiContext);
  const networker = useNetworker();

  const updateTips = useCallback(async (data: Tips) => {
    await networker(async () => {
      await setDoc(getTipsRef(), data);

      setSnackbar(labels.message.updateSuccess);
    });
  }, []);

  return { updateTips };
};
