import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { ModelCategory } from '../../entities';
import { getModelCategoryRef } from '../../infrastructures/firebase';

export const useModelCategory = (
  categoryId?: string,
): {
  isFetching: boolean;
  modelCategory: ModelCategory | null;
} => {
  const [isFetching, setIsFetching] = useState(true);
  const [modelCategory, setModelCategory] = useState<ModelCategory | null>(
    null,
  );

  useEffect(() => {
    if (!categoryId) {
      return;
    }

    const unsubscribe = onSnapshot(
      getModelCategoryRef(categoryId),
      (snapshot) => {
        const data = snapshot.data();

        if (data) {
          setModelCategory(data);
        }

        setIsFetching(false);
      },
      (error) => {
        console.error('catch useModelCategory error: ', error.message);
        setIsFetching(false);
      },
    );

    return unsubscribe;
  }, []);

  return { isFetching, modelCategory };
};
