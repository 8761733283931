import React, { FunctionComponent, ReactNode, useState } from 'react';
import {
  createinitialAuthStatus,
  createInitialdrawerIsOpenState,
  createInitialErrorState,
  createInitialSnackbarState,
  UiContext,
} from '../contexts';

type UiManagerProps = {
  children: ReactNode;
};

export const UiManager: FunctionComponent<UiManagerProps> = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(createinitialAuthStatus());
  const [error, setError] = useState(createInitialErrorState());
  const [snackbar, setSnackbar] = useState(createInitialSnackbarState());
  const [drawerIsOpen, setDrawerIsOpen] = useState(
    createInitialdrawerIsOpenState(),
  );

  return (
    <UiContext.Provider
      value={{
        authStatus,
        setAuthStatus,
        error,
        setError,
        snackbar,
        setSnackbar,
        drawerIsOpen,
        setDrawerIsOpen,
      }}
    >
      {children}
    </UiContext.Provider>
  );
};
