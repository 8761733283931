import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { AdminLayout } from '../../components/templates';
import { PageHeader } from '../../components/molecules';
import { TipsEditForm } from '../../components/features/tips/TipsEditForm';
import { labels } from '../../constants';
import { useTips } from '../../hooks';
import { createInitialTips } from '../../entities';

export function TipsEditPage(): JSX.Element {
  const { isFetching, tips } = useTips();

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.tips.edit} />
      <Box mt={3}>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <TipsEditForm tips={tips || createInitialTips()} />
        )}
      </Box>
    </AdminLayout>
  );
}
