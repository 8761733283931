import React, { FunctionComponent } from 'react';
import { Button, Card, CardActions, CardHeader, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { PropertyList } from '../../atoms';
import { createUserEditPath, labels } from '../../../constants';
import { PublicUser } from '../../../entities';
import { formatDate, formatDateTime } from '../../../utils/format-date';
import EditIcon from '@mui/icons-material/Edit';

type UserBasicPropertyProps = {
  publicUser: PublicUser;
};

export const UserBasicProperty: FunctionComponent<UserBasicPropertyProps> = ({
  publicUser,
}) => {
  return (
    <Card>
      <CardHeader title={labels.data.user.basicInfo} />
      <Divider />
      <PropertyList.Wrapper>
        <PropertyList.Item
          label={labels.data.user.name}
          value={publicUser.name}
        />
        <PropertyList.Item
          label={labels.data.user.height}
          value={`${publicUser.height} cm`}
        />
        <PropertyList.Item
          label={labels.data.user.birthday}
          value={formatDate(publicUser.birthday)}
        />
        <PropertyList.Item
          label={labels.data.user.race}
          value={publicUser.race}
        />
        <PropertyList.Item
          label={labels.data.user.bust}
          value={`${publicUser.bust} cm`}
        />
        <PropertyList.Item
          label={labels.data.user.waist}
          value={`${publicUser.waist} cm`}
        />
        <PropertyList.Item
          label={labels.data.user.hip}
          value={`${publicUser.hip} cm`}
        />
        <PropertyList.Item
          label={labels.data.user.updatedAt}
          value={formatDateTime(publicUser.updatedAt)}
        />
        <PropertyList.Item
          label={labels.data.user.createdAt}
          value={formatDateTime(publicUser.createdAt)}
        />
      </PropertyList.Wrapper>
      <CardActions>
        <Button
          component={Link}
          startIcon={<EditIcon fontSize='small' />}
          variant='outlined'
          to={createUserEditPath(publicUser.uid)}
        >
          {labels.form.actions.edit}
        </Button>
      </CardActions>
    </Card>
  );
};
