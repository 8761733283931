import { Score } from '../entities';
import { addErrorEventOnSentry } from '../infrastructures/sentry';

export const sumUpFashionModelScore = (score?: Score) => {
  try {
    if (!score) {
      throw new Error('引数のscoreが空です');
    }

    const sum =
      score.fashionModelScore.height +
      score.fashionModelScore.balance +
      score.fashionModelScore.legShape +
      score.fashionModelScore.limbLength +
      score.fashionModelScore.features +
      score.fashionModelScore.nose +
      score.fashionModelScore.skinQuality +
      score.fashionModelScore.age;

    const result = (sum / 130) * 100;
    console.log(`元のファッションモデル合計得点: ${sum}点`);
    console.log(`100点換算したファッションモデルポイント: ${result}ポイント`);

    return result;
  } catch (error) {
    addErrorEventOnSentry(error);
    return 0;
  }
};

export const sumUpCommercialModelScore = (score?: Score) => {
  try {
    if (!score) {
      throw new Error('引数のscoreが空です');
    }

    const sum =
      score.commercialModelScore.height +
      score.commercialModelScore.balance +
      score.commercialModelScore.legShape +
      score.commercialModelScore.smile +
      score.commercialModelScore.features +
      score.commercialModelScore.nose +
      score.commercialModelScore.skinQuality +
      score.commercialModelScore.age;

    const result = (sum / 130) * 100;
    console.log('元のコマーシャルモデル合計得点: ', sum, '点');
    console.log(
      '100点換算したコマーシャルモデルポイント: ',
      result,
      'ポイント',
    );

    return result;
  } catch (error) {
    addErrorEventOnSentry(error);
    return 0;
  }
};
