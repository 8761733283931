import React, { FunctionComponent } from 'react';
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { UserLog } from '../../../entities';
import { formatDateTime } from '../../../utils/format-date';
import { labels } from '../../../constants';

interface UserListTableProps {
  logs: UserLog[];
}

export const UserLogTable: FunctionComponent<UserListTableProps> = ({
  logs,
}) => {
  return (
    <Card>
      <CardHeader
        title={labels.data.user.logs}
        subheader='※最新の10件のみ取得'
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={'200px'}>
              {labels.data.userLog.createdAt}
            </TableCell>
            <TableCell>{labels.data.userLog.title}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((log, i) => {
            return (
              <TableRow key={i.toString()}>
                <TableCell>{formatDateTime(log.createdAt)}</TableCell>
                <TableCell>{log.title}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};
