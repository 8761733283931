import React, { FC } from 'react';
import { Card, CardHeader, Divider } from '@mui/material';
import { labels } from '../../../constants';
import { PropertyList } from '../../atoms';
import { Agency } from '../../../entities';

interface AgencyChartPropertyProps {
  agency: Agency;
}

export const AgencyChartProperty: FC<AgencyChartPropertyProps> = ({
  agency,
}) => (
  <Card>
    <CardHeader title={labels.data.agency.score} />
    <Divider />
    <PropertyList.Wrapper>
      <PropertyList.Item
        label={labels.data.scores.height}
        value={agency.score.height}
      />
      <PropertyList.Item
        label={labels.data.scores.balance}
        value={agency.score.balance}
      />
      <PropertyList.Item
        label={labels.data.scores.legShape}
        value={agency.score.legShape}
      />
      <PropertyList.Item
        label={labels.data.scores.features}
        value={agency.score.features}
      />
      <PropertyList.Item
        label={labels.data.scores.skinQuality}
        value={agency.score.skinQuality}
      />
      <PropertyList.Item
        label={labels.data.scores.age}
        value={agency.score.age}
      />
    </PropertyList.Wrapper>
  </Card>
);
