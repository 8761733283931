import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { AdminLayout } from '../../components/templates';
import { UserCommonForm } from '../../components/features/user/UserCommonForm';
import { PageHeader } from '../../components/molecules';
import { labels } from '../../constants';
import { useUser } from '../../hooks';
import { useParams } from 'react-router-dom';

export const UserEditPage = () => {
  const { uid } = useParams<{ uid: string }>();
  const { isFetching, user } = useUser(uid);

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.users.edit} />

      {/* 読み込み中はローディングアイコンを表示 */}
      {isFetching ? (
        <CircularProgress />
      ) : (
        <Box component='main'>
          {/* userが存在すればフォームを表示 */}
          {user ? (
            <UserCommonForm user={user} method='edit' />
          ) : (
            <p>Userが見つかりませんでした</p>
          )}
        </Box>
      )}
    </AdminLayout>
  );
};
