import { setDoc } from 'firebase/firestore';
import { labels } from '../constants';
import { LabelFormData } from '../entities';
import { getLabelRef } from '../infrastructures/firebase';
import { addErrorEventOnSentry } from '../infrastructures/sentry';
import { createUUID } from '../utils/create-unique-id';

/*
 * 一意のIDをクライアント側で作成しフォームからのPOSTデータに付与しつつ、
 * Firestore上のドキュメントIDにもしつつ、保存する
 */
const add = async (uid: string, formData: LabelFormData) => {
  try {
    const id = createUUID();
    const ref = getLabelRef(uid, id);
    const data = { ...formData, updatedAt: new Date() };

    await setDoc(ref, data);
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.addFailure);
  }
};

export const LabelRepository = {
  add,
};
