import React, { FunctionComponent, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { labels } from '../../../constants';
import { Label, PublicUser } from '../../../entities';
import { InputList } from '../../atoms';
import {
  ASYMMETRICAL_FACE_ITEMS,
  DENTITION_ITEMS,
  EYE_FEATURE_ITEMS,
  EYE_SHAPE_ITEMS,
  EYE_TYPE_ITEMS,
  FACE_TYPE_ITEMS,
  HAND_LENGTH_ITEMS,
  HEADS_TALL_ITEMS,
  KNEE_LENGTH_ITEMS,
  LEG_BALANCE_ITEMS,
  LEG_LENGTH_ITEMS,
  LEG_SHAPE_ITEMS,
  LIP_THICKNESS_ITEMS,
  MALOCCLUSION_ITEMS,
  MOUTH_SHAPE_ITEMS,
  MOUTH_SIZE_ITEMS,
  NOSE_RATIO_ITEMS,
  NOSE_SHAPE_FRONT_ITEMS,
  NOSE_SHAPE_SIDE_ITEMS,
  NOSE_SIZE_ITEMS,
  NO_CHIN_ITEMS,
  PELVIC_TENSION_ITEMS,
  RIDGE_OF_NOSE_ITEMS,
  SKIN_TYPE_ITEMS,
} from '../../../constants/items';
import { useLabelEditTool } from '../../../hooks';

type LabelFormProps = {
  isOpen: boolean;
  publicUser: PublicUser;
  onClose: () => void;
};

export const LabelModalForm: FunctionComponent<LabelFormProps> = ({
  isOpen,
  publicUser,
  onClose,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Label>({
    defaultValues: {
      headsTall: publicUser.headsTall,
      legLength: publicUser.legLength,
      legShape: publicUser.legShape,
      legBalance: publicUser.legBalance || [],
      kneeLength: publicUser.kneeLength,
      handLength: publicUser.handLength,
      faceType: publicUser.faceType,
      eyeType: publicUser.eyeType,
      eyeShape: publicUser.eyeShape,
      ridgeOfNose: publicUser.ridgeOfNose,
      noseSize: publicUser.noseSize,
      lipThickness: publicUser.lipThickness,
      mouthSize: publicUser.mouthSize,
      noseRatio: publicUser.noseRatio,
      noseShapeFront: publicUser.noseShapeFront || [],
      noseShapeSide: publicUser.noseShapeSide || [],
      skinType: publicUser.skinType || [],
      mouthShape: publicUser.mouthShape || [],
      dentition: publicUser.dentition,
      malocclusion: publicUser.malocclusion,
      pelvicTension: publicUser.pelvicTension,
      eyeFeature: publicUser.eyeFeature,
      asymmetricalFace: publicUser.asymmetricalFace,
      noChin: publicUser.noChin,
    },
  });

  useEffect(() => {
    reset({
      headsTall: publicUser.headsTall,
      legLength: publicUser.legLength,
      legShape: publicUser.legShape,
      legBalance: publicUser.legBalance || [],
      kneeLength: publicUser.kneeLength,
      handLength: publicUser.handLength,
      faceType: publicUser.faceType,
      eyeType: publicUser.eyeType,
      eyeShape: publicUser.eyeShape,
      ridgeOfNose: publicUser.ridgeOfNose,
      noseSize: publicUser.noseSize,
      lipThickness: publicUser.lipThickness,
      mouthSize: publicUser.mouthSize,
      noseRatio: publicUser.noseRatio,
      noseShapeFront: publicUser.noseShapeFront || [],
      noseShapeSide: publicUser.noseShapeSide || [],
      skinType: publicUser.skinType || [],
      mouthShape: publicUser.mouthShape || [],
      dentition: publicUser.dentition,
      malocclusion: publicUser.malocclusion,
      pelvicTension: publicUser.pelvicTension,
      eyeFeature: publicUser.eyeFeature,
      asymmetricalFace: publicUser.asymmetricalFace,
      noChin: publicUser.noChin,
    });
  }, [publicUser]);

  const { addLabel } = useLabelEditTool();

  // 送信処理では、ラベルの追加とユーザー情報の更新とモーダルの閉じる処理を同期的に行う
  const onSubmit: SubmitHandler<Label> = async (formData) => {
    await addLabel(publicUser.uid, formData).then(() => {
      onClose();
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      fullScreen
      sx={{ margin: 4 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{labels.pages.labels.edit}</DialogTitle>
        <Divider />
        <DialogContent>
          <ImageList cols={4} gap={24} sx={{ marginBottom: 0, marginTop: 0 }}>
            {/* バストアップ画像 */}
            {publicUser?.bustupImgUrl && (
              <ImageListItem>
                <img
                  src={publicUser.bustupImgUrl}
                  alt={labels.data.userImage.bust}
                  loading='lazy'
                />
                <ImageListItemBar title={labels.data.userImage.bust} />
              </ImageListItem>
            )}

            {/* 全身画像 */}
            {publicUser?.fullbodyImgUrl && (
              <ImageListItem>
                <img
                  src={publicUser.fullbodyImgUrl}
                  alt={labels.data.userImage.fullbody}
                  loading='lazy'
                />
                <ImageListItemBar title={labels.data.userImage.fullbody} />
              </ImageListItem>
            )}

            {/* 真横からの画像 */}
            {publicUser?.sideImgUrl && (
              <ImageListItem>
                <img
                  src={publicUser.sideImgUrl}
                  alt={labels.data.userImage.side}
                  loading='lazy'
                />
                <ImageListItemBar title={labels.data.userImage.side} />
              </ImageListItem>
            )}

            {/* 笑顔の画像 */}
            {publicUser?.smileImgUrl && (
              <ImageListItem>
                <img
                  src={publicUser.smileImgUrl}
                  alt={labels.data.userImage.smile}
                  loading='lazy'
                />
                <ImageListItemBar title={labels.data.userImage.smile} />
              </ImageListItem>
            )}
          </ImageList>
        </DialogContent>
        <DialogContent sx={{ padding: 0 }}>
          <InputList.Wrapper>
            <InputList.Item label={labels.data.analyses.headsTall}>
              <Controller
                name='headsTall'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.headsTall)}
                    helperText={errors?.headsTall?.message}
                  >
                    {Object.keys(HEADS_TALL_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {HEADS_TALL_ITEMS[key as keyof typeof HEADS_TALL_ITEMS]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.legLength}>
              <Controller
                name='legLength'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.legLength)}
                    helperText={errors?.legLength?.message}
                  >
                    {Object.keys(LEG_LENGTH_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {LEG_LENGTH_ITEMS[key as keyof typeof LEG_LENGTH_ITEMS]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.legShape}>
              <Controller
                name='legShape'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.legShape)}
                    helperText={errors?.legShape?.message}
                  >
                    {Object.keys(LEG_SHAPE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {LEG_SHAPE_ITEMS[key as keyof typeof LEG_SHAPE_ITEMS]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.legBalance}>
              <Controller
                name='legBalance'
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      fullWidth
                      multiple
                      value={field.value}
                      onChange={field.onChange}
                      size='small'
                      error={Boolean(errors?.legBalance)}
                    >
                      {Object.keys(LEG_BALANCE_ITEMS).map((key) => (
                        <MenuItem key={key} value={key}>
                          {
                            LEG_BALANCE_ITEMS[
                              key as keyof typeof LEG_BALANCE_ITEMS
                            ]
                          }
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.kneeLength}>
              <Controller
                name='kneeLength'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.kneeLength)}
                    helperText={errors?.kneeLength?.message}
                  >
                    {Object.keys(KNEE_LENGTH_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          KNEE_LENGTH_ITEMS[
                            key as keyof typeof KNEE_LENGTH_ITEMS
                          ]
                        }
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.handLength}>
              <Controller
                name='handLength'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.handLength)}
                    helperText={errors?.handLength?.message}
                  >
                    {Object.keys(HAND_LENGTH_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          HAND_LENGTH_ITEMS[
                            key as keyof typeof HAND_LENGTH_ITEMS
                          ]
                        }
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.faceType}>
              <Controller
                name='faceType'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.faceType)}
                    helperText={errors?.faceType?.message}
                  >
                    {Object.keys(FACE_TYPE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {FACE_TYPE_ITEMS[key as keyof typeof FACE_TYPE_ITEMS]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.eyeType}>
              <Controller
                name='eyeType'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.eyeType)}
                    helperText={errors?.eyeType?.message}
                  >
                    {Object.keys(EYE_TYPE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {EYE_TYPE_ITEMS[key as keyof typeof EYE_TYPE_ITEMS]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.eyeShape}>
              <Controller
                name='eyeShape'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.eyeShape)}
                    helperText={errors?.eyeShape?.message}
                  >
                    {Object.keys(EYE_SHAPE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {EYE_SHAPE_ITEMS[key as keyof typeof EYE_SHAPE_ITEMS]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.ridgeOfNose}>
              <Controller
                name='ridgeOfNose'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.ridgeOfNose)}
                    helperText={errors?.ridgeOfNose?.message}
                  >
                    {Object.keys(RIDGE_OF_NOSE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          RIDGE_OF_NOSE_ITEMS[
                            key as keyof typeof RIDGE_OF_NOSE_ITEMS
                          ]
                        }
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.noseSize}>
              <Controller
                name='noseSize'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.noseSize)}
                    helperText={errors?.noseSize?.message}
                  >
                    {Object.keys(NOSE_SIZE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {NOSE_SIZE_ITEMS[key as keyof typeof NOSE_SIZE_ITEMS]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.lipThickness}>
              <Controller
                name='lipThickness'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.lipThickness)}
                    helperText={errors?.lipThickness?.message}
                  >
                    {Object.keys(LIP_THICKNESS_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          LIP_THICKNESS_ITEMS[
                            key as keyof typeof LIP_THICKNESS_ITEMS
                          ]
                        }
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.mouthSize}>
              <Controller
                name='mouthSize'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.mouthSize)}
                    helperText={errors?.mouthSize?.message}
                  >
                    {Object.keys(MOUTH_SIZE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {MOUTH_SIZE_ITEMS[key as keyof typeof MOUTH_SIZE_ITEMS]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.noseRatio}>
              <Controller
                name='noseRatio'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.noseRatio)}
                    helperText={errors?.noseRatio?.message}
                  >
                    {Object.keys(NOSE_RATIO_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {NOSE_RATIO_ITEMS[key as keyof typeof NOSE_RATIO_ITEMS]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.noseShapeFront}>
              <Controller
                name='noseShapeFront'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    multiple
                    value={field.value}
                    onChange={field.onChange}
                    size='small'
                    error={Boolean(errors?.noseShapeFront)}
                  >
                    {Object.keys(NOSE_SHAPE_FRONT_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          NOSE_SHAPE_FRONT_ITEMS[
                            key as keyof typeof NOSE_SHAPE_FRONT_ITEMS
                          ]
                        }
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.noseShapeSide}>
              <Controller
                name='noseShapeSide'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    multiple
                    value={field.value}
                    onChange={field.onChange}
                    size='small'
                    error={Boolean(errors?.noseShapeSide)}
                  >
                    {Object.keys(NOSE_SHAPE_SIDE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          NOSE_SHAPE_SIDE_ITEMS[
                            key as keyof typeof NOSE_SHAPE_SIDE_ITEMS
                          ]
                        }
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.skinType}>
              <Controller
                name='skinType'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    multiple
                    value={field.value}
                    onChange={field.onChange}
                    size='small'
                    error={Boolean(errors?.skinType)}
                  >
                    {Object.keys(SKIN_TYPE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {SKIN_TYPE_ITEMS[key as keyof typeof SKIN_TYPE_ITEMS]}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.mouthShape}>
              <Controller
                name='mouthShape'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    multiple
                    value={field.value}
                    onChange={field.onChange}
                    size='small'
                    error={Boolean(errors?.mouthShape)}
                  >
                    {Object.keys(MOUTH_SHAPE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          MOUTH_SHAPE_ITEMS[
                            key as keyof typeof MOUTH_SHAPE_ITEMS
                          ]
                        }
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </InputList.Item>

            <InputList.Item label={labels.data.analyses.dentition}>
              <Controller
                name='dentition'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.dentition)}
                    helperText={errors?.dentition?.message}
                  >
                    {Object.keys(DENTITION_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {DENTITION_ITEMS[key as keyof typeof DENTITION_ITEMS]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            {/* 噛み合わせ */}
            <InputList.Item label={labels.data.analyses.malocclusion}>
              <Controller
                name='malocclusion'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.malocclusion)}
                    helperText={errors?.malocclusion?.message}
                  >
                    {Object.keys(MALOCCLUSION_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          MALOCCLUSION_ITEMS[
                            key as keyof typeof MALOCCLUSION_ITEMS
                          ]
                        }
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            {/* 骨盤の張り */}
            <InputList.Item label={labels.data.analyses.pelvicTension}>
              <Controller
                name='pelvicTension'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.pelvicTension)}
                    helperText={errors?.pelvicTension?.message}
                  >
                    {Object.keys(PELVIC_TENSION_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          PELVIC_TENSION_ITEMS[
                            key as keyof typeof PELVIC_TENSION_ITEMS
                          ]
                        }
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            {/* 目の特徴 */}
            <InputList.Item label={labels.data.analyses.eyeFeature}>
              <Controller
                name='eyeFeature'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.eyeFeature)}
                    helperText={errors?.eyeFeature?.message}
                  >
                    {Object.keys(EYE_FEATURE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          EYE_FEATURE_ITEMS[
                            key as keyof typeof EYE_FEATURE_ITEMS
                          ]
                        }
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            {/* 顔の左右対称性 */}
            <InputList.Item label={labels.data.analyses.asymmetricalFace}>
              <Controller
                name='asymmetricalFace'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.asymmetricalFace)}
                    helperText={errors?.asymmetricalFace?.message}
                  >
                    {Object.keys(ASYMMETRICAL_FACE_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          ASYMMETRICAL_FACE_ITEMS[
                            key as keyof typeof ASYMMETRICAL_FACE_ITEMS
                          ]
                        }
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>

            {/* 顎なし */}
            <InputList.Item label={labels.data.analyses.noChin}>
              <Controller
                name='noChin'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    size='small'
                    error={Boolean(errors?.noChin)}
                    helperText={errors?.noChin?.message}
                  >
                    {Object.keys(NO_CHIN_ITEMS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {NO_CHIN_ITEMS[key as keyof typeof NO_CHIN_ITEMS]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </InputList.Item>
          </InputList.Wrapper>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button sx={{ m: 1 }} variant='outlined' onClick={onClose}>
            {labels.form.actions.cancel}
          </Button>

          {isSubmitting ? (
            <Button sx={{ m: 1 }} variant='contained' disabled>
              {labels.form.actions.loading}
            </Button>
          ) : (
            <Button sx={{ m: 1 }} type='submit' variant='contained'>
              {labels.form.actions.save}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};
