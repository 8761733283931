import React, { FC } from 'react';
import { Card, CardHeader, Divider } from '@mui/material';
import { labels } from '../../../constants';
import { PropertyList } from '../../atoms';
import { Agency, AgencyTypeSlug } from '../../../entities';
import { formatDateTime } from '../../../utils/format-date';
import { getAgencyType } from '../../../domains/agency-type';

interface AgencyBasicPropertyProps {
  agency: Agency;
}

export const AgencyBasicProperty: FC<AgencyBasicPropertyProps> = ({
  agency,
}) => {
  const agencyType = getAgencyType(agency.type as AgencyTypeSlug);

  return (
    <Card>
      <CardHeader title={labels.data.agency.basicInfo} />
      <Divider />
      <PropertyList.Wrapper>
        <PropertyList.Item
          label={labels.data.agency.name}
          value={agency.name}
        />
        <PropertyList.Item
          label={labels.data.agency.area}
          value={agency.area}
        />
        <PropertyList.Item label={labels.data.agency.url} value={agency.url} />
        <PropertyList.Item
          label={labels.data.agency.inheritance}
          value={agency.inheritance}
        />
        <PropertyList.Item
          label={labels.data.agency.type}
          value={agencyType?.name}
        />
        <PropertyList.Item
          label={labels.data.agency.acceptanceAge}
          value={`${agency.minAge}歳～${agency.maxAge}歳`}
        />
        <PropertyList.Item
          label={labels.data.agency.acceptanceHeight}
          value={`${agency.minHeight}cm～${agency.maxHeight}cm`}
        />
        <PropertyList.Item
          label={labels.data.agency.totalScore}
          value={`${agency.totalScore}点`}
        />
        <PropertyList.Item
          label={labels.data.agency.createdAt}
          value={formatDateTime(agency.createdAt)}
        />
        <PropertyList.Item
          label={labels.data.agency.updatedAt}
          value={formatDateTime(agency.updatedAt)}
        />
      </PropertyList.Wrapper>
    </Card>
  );
};
