import React from 'react';
import { Box } from '@mui/material';
import { AdminLayout } from '../../components/templates';
import { UserCommonForm } from '../../components/features/user/UserCommonForm';
import { PageHeader } from '../../components/molecules';
import { labels } from '../../constants';
import { createInitialUser } from '../../entities';

export const UserAddPage = () => {
  const user = createInitialUser();

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.users.add} />
      <Box component='main'>
        <UserCommonForm user={user} method='add' />
      </Box>
    </AdminLayout>
  );
};
