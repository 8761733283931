import React, { FunctionComponent } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Tips } from '../../../entities';
import { labels } from '../../../constants';
import { useForm, Controller } from 'react-hook-form';
import { useTipsEditTools } from '../../../hooks';

type SubHeadingProps = {
  index: number;
  text: string;
};

const SubHeading: FunctionComponent<SubHeadingProps> = ({ index, text }) => (
  <Typography variant='h6' mt={3}>
    {index}. {text}
  </Typography>
);

type TipsEditFormProps = {
  tips: Tips;
};

type FormData = Tips;

export const TipsEditForm: FunctionComponent<TipsEditFormProps> = ({
  tips,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      fashionModelScore: tips.fashionModelScore,
      commercialModelScore: tips.commercialModelScore,
      updatedAt: new Date(),
    },
  });

  const { updateTips } = useTipsEditTools();

  const onSubmit = handleSubmit(async (data) => {
    await updateTips(data);
  });

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {/* ファッションモデル */}
        <Grid item xs={12}>
          <Card>
            <CardHeader title={labels.data.tips.fashionModel} />
            <Divider />
            <CardContent>
              {/* 年齢 */}
              <SubHeading index={1} text='年齢' />
              <Controller
                name='fashionModelScore.age.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.fashionModelScore?.age?.content}
                    helperText={
                      errors?.fashionModelScore?.age?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='fashionModelScore.age.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.fashionModelScore?.age?.url}
                    helperText={errors?.fashionModelScore?.age?.url?.message}
                  />
                )}
              />

              {/* 身長 */}
              <SubHeading index={2} text='身長' />
              <Controller
                name='fashionModelScore.height.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.fashionModelScore?.height?.content}
                    helperText={
                      errors?.fashionModelScore?.height?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='fashionModelScore.height.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.fashionModelScore?.height?.url}
                    helperText={errors?.fashionModelScore?.height?.url?.message}
                  />
                )}
              />

              {/* 全身バランス */}
              <SubHeading index={3} text='全身バランス' />
              <Controller
                name='fashionModelScore.balance.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.fashionModelScore?.balance?.content}
                    helperText={
                      errors?.fashionModelScore?.balance?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='fashionModelScore.balance.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.fashionModelScore?.balance?.url}
                    helperText={
                      errors?.fashionModelScore?.balance?.url?.message
                    }
                  />
                )}
              />

              {/* 脚の形 */}
              <SubHeading index={4} text='脚の形' />
              <Controller
                name='fashionModelScore.legShape.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.fashionModelScore?.legShape?.content}
                    helperText={
                      errors?.fashionModelScore?.legShape?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='fashionModelScore.legShape.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.fashionModelScore?.legShape?.url}
                    helperText={
                      errors?.fashionModelScore?.legShape?.url?.message
                    }
                  />
                )}
              />

              {/* 手足の長さ */}
              <SubHeading index={5} text='手足の長さ' />
              <Controller
                name='fashionModelScore.limbLength.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.fashionModelScore?.legShape?.content}
                    helperText={
                      errors?.fashionModelScore?.legShape?.url?.message
                    }
                  />
                )}
              />
              <Controller
                name='fashionModelScore.limbLength.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.fashionModelScore?.limbLength?.url}
                    helperText={
                      errors?.fashionModelScore?.limbLength?.url?.message
                    }
                  />
                )}
              />

              {/* 顔立ち */}
              <SubHeading index={6} text='顔立ち' />
              <Controller
                name='fashionModelScore.features.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.fashionModelScore?.features?.content}
                    helperText={
                      errors?.fashionModelScore?.features?.content?.message
                    }
                  />
                )}
              />

              <Controller
                name='fashionModelScore.features.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.fashionModelScore?.features?.url}
                    helperText={
                      errors?.fashionModelScore?.features?.url?.message
                    }
                  />
                )}
              />

              {/* 鼻 */}
              <SubHeading index={7} text='鼻' />
              <Controller
                name='fashionModelScore.nose.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.fashionModelScore?.nose?.content}
                    helperText={
                      errors?.fashionModelScore?.nose?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='fashionModelScore.nose.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.fashionModelScore?.nose?.url}
                    helperText={errors?.fashionModelScore?.nose?.url?.message}
                  />
                )}
              />

              {/* 肌 */}
              <SubHeading index={8} text='肌質' />
              <Controller
                name='fashionModelScore.skinQuality.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.fashionModelScore?.skinQuality?.content}
                    helperText={
                      errors?.fashionModelScore?.skinQuality?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='fashionModelScore.skinQuality.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.fashionModelScore?.skinQuality?.url}
                    helperText={
                      errors?.fashionModelScore?.skinQuality?.url?.message
                    }
                  />
                )}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* コマーシャルモデル */}
        <Grid item xs={12}>
          <Card>
            <CardHeader title={labels.data.tips.commercialModel} />
            <Divider />
            <CardContent>
              {/* 年齢 */}
              <SubHeading index={1} text='年齢' />
              <Controller
                name='commercialModelScore.age.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.commercialModelScore?.age?.content}
                    helperText={
                      errors?.commercialModelScore?.age?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='commercialModelScore.age.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.commercialModelScore?.age?.url}
                    helperText={errors?.commercialModelScore?.age?.url?.message}
                  />
                )}
              />

              {/* 身長 */}
              <SubHeading index={2} text='身長' />
              <Controller
                name='commercialModelScore.height.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.commercialModelScore?.height?.content}
                    helperText={
                      errors?.commercialModelScore?.height?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='commercialModelScore.height.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.commercialModelScore?.height?.url}
                    helperText={
                      errors?.commercialModelScore?.height?.url?.message
                    }
                  />
                )}
              />

              {/* 全身のバランス */}
              <SubHeading index={3} text='全身のバランス' />
              <Controller
                name='commercialModelScore.balance.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.commercialModelScore?.balance?.content}
                    helperText={
                      errors?.commercialModelScore?.balance?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='commercialModelScore.balance.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.commercialModelScore?.balance?.url}
                    helperText={
                      errors?.commercialModelScore?.balance?.url?.message
                    }
                  />
                )}
              />

              {/* 脚の形 */}
              <SubHeading index={4} text='脚の形' />
              <Controller
                name='commercialModelScore.legShape.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.commercialModelScore?.legShape?.content}
                    helperText={
                      errors?.commercialModelScore?.legShape?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='commercialModelScore.legShape.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.commercialModelScore?.legShape?.url}
                    helperText={
                      errors?.commercialModelScore?.legShape?.url?.message
                    }
                  />
                )}
              />

              {/* 笑顔 */}
              <SubHeading index={5} text='笑顔' />
              <Controller
                name='commercialModelScore.smile.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.commercialModelScore?.smile?.content}
                    helperText={
                      errors?.commercialModelScore?.smile?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='commercialModelScore.smile.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.commercialModelScore?.smile?.url}
                    helperText={
                      errors?.commercialModelScore?.smile?.url?.message
                    }
                  />
                )}
              />

              {/* 顔立ち */}
              <SubHeading index={6} text='顔立ち' />
              <Controller
                name='commercialModelScore.features.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.commercialModelScore?.features?.content}
                    helperText={
                      errors?.commercialModelScore?.features?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='commercialModelScore.features.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.commercialModelScore?.features?.url}
                    helperText={
                      errors?.commercialModelScore?.features?.url?.message
                    }
                  />
                )}
              />

              {/* 鼻 */}
              <SubHeading index={7} text='鼻' />
              <Controller
                name='commercialModelScore.nose.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.commercialModelScore?.nose?.content}
                    helperText={
                      errors?.commercialModelScore?.nose?.content?.message
                    }
                  />
                )}
              />
              <Controller
                name='commercialModelScore.nose.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.commercialModelScore?.nose?.url}
                    helperText={
                      errors?.commercialModelScore?.nose?.url?.message
                    }
                  />
                )}
              />

              {/* 肌 */}
              <SubHeading index={8} text='肌質' />
              <Controller
                name='commercialModelScore.skinQuality.content'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.commercialModelScore?.skinQuality?.content}
                    helperText={
                      errors?.commercialModelScore?.skinQuality?.content
                        ?.message
                    }
                  />
                )}
              />
              <Controller
                name='commercialModelScore.skinQuality.url'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type='url'
                    error={!!errors.commercialModelScore?.skinQuality?.url}
                    helperText={
                      errors?.commercialModelScore?.skinQuality?.url?.message
                    }
                  />
                )}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* フォームアクション */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {isSubmitting ? (
              <Button variant='contained' disabled>
                {labels.form.actions.loading}
              </Button>
            ) : (
              <Button type='submit' variant='contained' disabled={!isDirty}>
                {labels.form.actions.update}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
