import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  type DocumentData,
} from 'firebase/firestore';

export type User = {
  uid: string;
  name: string;
  height: number;
  birthday: Date;
  race: string;
  bust: number;
  waist: number;
  hip: number;
  createdAt: Date;
  updatedAt: Date;
};

export type UserFormData = Omit<User, 'uid'>;

// 初期のフォームインスタンス用
export const createInitialUser = (): User => {
  return {
    uid: '', // firebase保存時に一意のIDを付与する
    name: '',
    height: 0,
    birthday: new Date(),
    race: '',
    bust: 0,
    waist: 0,
    hip: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
};

/**
 * Firestore のドキュメントと User オブジェクトの型変換を行います。
 */
export const userConverter: FirestoreDataConverter<User> = {
  /**
   * User オブジェクトを Firestore ドキュメントデータへ変換します。
   */
  toFirestore(user: User): DocumentData {
    return {
      uid: user.uid,
      name: user.name,
      height: +user.height,
      birthday: user.birthday,
      race: user.race,
      bust: +user.bust,
      waist: +user.waist,
      hip: +user.hip,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
    };
  },

  /**
   * Firestore ドキュメントデータを User オブジェクトへ変換します。
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): User {
    const data = snapshot.data(options);

    return {
      uid: data.uid,
      name: data.name,
      height: data.height,
      birthday: data.birthday.toDate(),
      race: data.race,
      bust: data.bust,
      waist: data.waist,
      hip: data.hip,
      createdAt: data.createdAt.toDate(),
      updatedAt: data.updatedAt.toDate(),
    };
  },
};
