import React, { FC, useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';

type FilePreviewProps = {
  file?: File;
};

export const FilePreview: FC<FilePreviewProps> = ({ file }) => {
  const [url, setUrl] = useState<string>('');
  const isLoading = file && !url;

  useEffect(() => {
    if (!file) {
      return;
    }

    let reader: FileReader | null = new FileReader();

    reader.onloadend = () => {
      const res = reader?.result;
      if (res && typeof res === 'string') {
        setUrl(res);
      }
    };

    reader.readAsDataURL(file);

    return () => {
      reader = null;
    };
  }, [file]);

  return file ? (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Box
          component='img'
          src={url}
          alt={file.name}
          maxWidth='300px'
          width='100%'
          height='auto'
        />
      )}
    </>
  ) : null;
};
