import { setDoc } from 'firebase/firestore';
import { labels } from '../constants';
import { ReportFormData } from '../entities';
import { getUserReportRef } from '../infrastructures/firebase';
import { addErrorEventOnSentry } from '../infrastructures/sentry';
import { createUUID } from '../utils/create-unique-id';

/*
 * 一意のIDをクライアント側で作成しフォームからのPOSTデータに付与しつつ、
 * Firestore上のドキュメントIDにもしつつ、保存する
 */
const add = async (uid: string, formData: ReportFormData) => {
  try {
    const id = createUUID();
    const ref = getUserReportRef(uid, id);
    const formDataWithNewID = {
      ...formData,
      reportId: id,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    await setDoc(ref, formDataWithNewID);
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.addFailure);
  }
};

export const ReportRepository = {
  add,
};
