import React, { FunctionComponent, ReactNode } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

type InputListWrapperProps = {
  children: ReactNode;
};

const InputListWrapper: FunctionComponent<InputListWrapperProps> = ({
  children,
}) => {
  return <List disablePadding>{children}</List>;
};

interface InputListItemProps extends ListItemProps {
  label: string;
  children: ReactNode;
}

const InputListItem: FunctionComponent<InputListItemProps> = ({
  label,
  children,
}) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const align = mdUp ? 'horizontal' : 'vertical';

  return (
    <ListItem sx={{ px: 3, py: 1.5 }} divider>
      <ListItemText
        disableTypography
        primary={
          <Typography
            sx={{ minWidth: align === 'vertical' ? 'inherit' : 180 }}
            variant='subtitle2'
          >
            {label}
          </Typography>
        }
        secondary={
          <Box sx={{ flex: 1, mt: align === 'vertical' ? 0.5 : 0 }}>
            {children}
          </Box>
        }
        sx={{
          display: 'flex',
          flexDirection: align === 'vertical' ? 'column' : 'row',
          my: 0,
          alignItems: 'center',
        }}
      />
    </ListItem>
  );
};

export const InputList = {
  Wrapper: InputListWrapper,
  Item: InputListItem,
};
