import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { AdminLayout } from '../../components/templates';
import { PageHeader } from '../../components/molecules';
import { labels } from '../../constants';
import { ModelCategoryCommonForm } from '../../components/features/model-category/ModelCategoryCommonForm';
import { useParams } from 'react-router-dom';
import { useModelCategory } from '../../hooks';

export const ModelCategoryEditPage = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { isFetching, modelCategory } = useModelCategory(categoryId);

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.modelCategory.edit} />
      <Box component='main'>
        {/* 読み込み中はローディングアイコンを表示 */}
        {isFetching || !modelCategory ? (
          <CircularProgress />
        ) : (
          <ModelCategoryCommonForm
            modelCategory={modelCategory}
            method='edit'
          />
        )}
      </Box>
    </AdminLayout>
  );
};
