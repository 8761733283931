import React, { useState } from 'react';
import type { MouseEvent } from 'react';
import { Card, CardHeader, CircularProgress, Divider } from '@mui/material';
import { PageHeader } from '../../components/molecules';
import { AdminLayout } from '../../components/templates';
import { labels, PATH } from '../../constants';
import { UserListTable } from '../../components/features/user/UserListTable';
import { AddButton } from '../../components/atoms';
import { usePublicUsers } from '../../hooks';
import { applyPagination } from '../../utils/apply-pagination';

const rowsPerPage = 10;

export const UserListPage = () => {
  const { isFetching, publicUsers } = usePublicUsers();
  const [page, setPage] = useState<number>(0);

  const paginatedUsers = applyPagination(publicUsers, page, rowsPerPage);

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  return (
    <AdminLayout>
      <PageHeader
        label={labels.pages.users.list}
        button={
          <AddButton label={labels.form.actions.add} to={PATH.USER_ADD} />
        }
      />
      <Card>
        <CardHeader title={labels.pages.users.list} />
        <Divider />

        {/* 読み込み中はローディングアイコンを表示 */}
        {isFetching ? (
          <CircularProgress />
        ) : (
          <UserListTable
            publicUsers={paginatedUsers}
            usersCount={publicUsers.length}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        )}
      </Card>
    </AdminLayout>
  );
};
