import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  type DocumentData,
} from 'firebase/firestore';

export type Label = {
  headsTall: string;
  legLength: string;
  legShape: string;
  legBalance: string[];
  kneeLength: string;
  handLength: string;
  faceType: string;
  eyeType: string;
  eyeShape: string;
  ridgeOfNose: string;
  noseSize: string;
  lipThickness: string;
  mouthSize: string;
  noseRatio: string;
  noseShapeFront: string[];
  noseShapeSide: string[];
  skinType: string[];
  mouthShape: string[];
  dentition: string;
  malocclusion: string;
  pelvicTension: string;
  eyeFeature: string;
  asymmetricalFace: string;
  noChin: string;
  updatedAt: Date;
};

export type LabelFormData = Omit<Label, 'updatedAt'>;

/**
 * Firestore のドキュメントと Label オブジェクトの型変換を行います。
 */
export const LabelConverter: FirestoreDataConverter<Label> = {
  /**
   * Label オブジェクトを Firestore ドキュメントデータへ変換します。
   */
  toFirestore(label: Label): DocumentData {
    return {
      headsTall: label.headsTall,
      legLength: label.legLength,
      legShape: label.legShape,
      legBalance: label.legBalance,
      kneeLength: label.kneeLength,
      handLength: label.handLength,
      faceType: label.faceType,
      eyeType: label.eyeType,
      eyeShape: label.eyeShape,
      ridgeOfNose: label.ridgeOfNose,
      noseSize: label.noseSize,
      lipThickness: label.lipThickness,
      mouthSize: label.mouthSize,
      noseRatio: label.noseRatio,
      noseShapeFront: label.noseShapeFront,
      noseShapeSide: label.noseShapeSide,
      skinType: label.skinType,
      mouthShape: label.mouthShape,
      dentition: label.dentition,
      malocclusion: label.malocclusion,
      pelvicTension: label.pelvicTension,
      eyeFeature: label.eyeFeature,
      asymmetricalFace: label.asymmetricalFace,
      noChin: label.noChin,
      updatedAt: label.updatedAt,
    };
  },

  /**
   * Firestore ドキュメントデータを Label オブジェクトへ変換します。
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Label {
    const data = snapshot.data(options);

    return {
      headsTall: data.headsTall,
      legLength: data.legLength,
      legShape: data.legShape,
      legBalance: data.legBalance,
      kneeLength: data.kneeLength,
      handLength: data.handLength,
      faceType: data.faceType,
      eyeType: data.eyeType,
      eyeShape: data.eyeShape,
      ridgeOfNose: data.ridgeOfNose,
      noseSize: data.noseSize,
      lipThickness: data.lipThickness,
      mouthSize: data.mouthSize,
      noseRatio: data.noseRatio,
      noseShapeFront: data.noseShapeFront,
      noseShapeSide: data.noseShapeSide,
      skinType: data.skinType,
      mouthShape: data.mouthShape,
      dentition: data.dentition,
      malocclusion: data.malocclusion,
      pelvicTension: data.pelvicTension,
      eyeFeature: data.eyeFeature,
      asymmetricalFace: data.asymmetricalFace,
      noChin: data.noChin,
      updatedAt: data.updatedAt.toDate(),
    };
  },
};
