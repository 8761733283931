import React, { FunctionComponent } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Stack,
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { labels } from '../../../constants';
import { UserImageFormData } from '../../../entities';
import { useParams } from 'react-router-dom';
import { useUserImageEditTool } from '../../../hooks';

type UserImageCommonFormProps = {
  onClose: () => void;
};

export const UserImageCommonModalForm: FunctionComponent<
  UserImageCommonFormProps
> = ({ onClose }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserImageFormData>();

  const { uid } = useParams<{ uid: string }>();
  const { uploadImages } = useUserImageEditTool();

  const onSubmit: SubmitHandler<UserImageFormData> = async (formData) => {
    uploadImages(formData, uid).then(() => onClose());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>{labels.data.user.images.title}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={4}
              whiteSpace='nowrap'
            >
              <FormLabel sx={{ width: 200 }}>
                {labels.data.user.images.bust}
              </FormLabel>
              <Controller
                name='bustupImg'
                control={control}
                render={({ field }) => (
                  <FormControl margin='normal'>
                    <input
                      type='file'
                      accept='.jpg, .jpeg, .png'
                      onChange={(e) => {
                        if (e.target.files) {
                          field.onChange(e.target.files[0]);
                        }
                      }}
                    />
                    {errors?.bustupImg ? (
                      <FormHelperText error>
                        {errors?.bustupImg?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={4}
              whiteSpace='nowrap'
            >
              <FormLabel sx={{ width: 200 }}>
                {labels.data.user.images.fullbody}
              </FormLabel>
              <Controller
                name='fullbodyImg'
                control={control}
                render={({ field }) => (
                  <FormControl margin='normal'>
                    <input
                      type='file'
                      accept='.jpg, .jpeg, .png'
                      onChange={(e) => {
                        if (e.target.files) {
                          field.onChange(e.target.files[0]);
                        }
                      }}
                    />
                    {errors?.fullbodyImg ? (
                      <FormHelperText error>
                        {errors?.fullbodyImg?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={4}
              whiteSpace='nowrap'
            >
              <FormLabel sx={{ width: 200 }}>
                {labels.data.user.images.side}
              </FormLabel>
              <Controller
                name='sideImg'
                control={control}
                render={({ field }) => (
                  <FormControl margin='normal'>
                    <input
                      type='file'
                      accept='.jpg, .jpeg, .png'
                      onChange={(e) => {
                        if (e.target.files) {
                          field.onChange(e.target.files[0]);
                        }
                      }}
                    />
                    {errors?.sideImg ? (
                      <FormHelperText error>
                        {errors?.sideImg?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={4}
              whiteSpace='nowrap'
            >
              <FormLabel sx={{ width: 200 }}>
                {labels.data.user.images.smile}
              </FormLabel>
              <Controller
                name='smileImg'
                control={control}
                render={({ field }) => (
                  <FormControl margin='normal'>
                    <input
                      type='file'
                      accept='.jpg, .jpeg, .png'
                      onChange={(e) => {
                        if (e.target.files) {
                          field.onChange(e.target.files[0]);
                        }
                      }}
                    />
                    {errors?.smileImg ? (
                      <FormHelperText error>
                        {errors?.smileImg?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{labels.form.actions.cancel}</Button>

        {isSubmitting ? (
          <Button variant='contained' disabled>
            {labels.form.actions.loading}
          </Button>
        ) : (
          <Button type='submit'>{labels.form.actions.save}</Button>
        )}
      </DialogActions>
    </form>
  );
};
