import { deleteDoc, setDoc } from 'firebase/firestore';
import { labels } from '../constants';
import { UserFormData } from '../entities';
import { getUserRef } from '../infrastructures/firebase';
import { addErrorEventOnSentry } from '../infrastructures/sentry';
import { createUUID } from '../utils/create-unique-id';

/*
 * 一意のIDをクライアント側で作成しフォームからのPOSTデータに付与しつつ、
 * Firestore上のドキュメントIDにもしつつ、保存する
 */
const add = async (formData: UserFormData) => {
  try {
    const uid = createUUID();
    const ref = getUserRef(uid);
    const formDataWithNewID = { ...formData, uid };

    await setDoc(ref, formDataWithNewID);
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.addFailure);
  }
};

const set = async (uid: string, formData: UserFormData) => {
  try {
    const ref = getUserRef(uid);
    const formDataWithNewID = { ...formData, uid };

    await setDoc(ref, formDataWithNewID);
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.updateFailure);
  }
};

const remove = async (uid: string) => {
  try {
    const ref = getUserRef(uid);

    await deleteDoc(ref);
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.deleteFailure);
  }
};

export const UserRepository = {
  add,
  set,
  remove,
};
