import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  serverTimestamp,
  SnapshotOptions,
  type DocumentData,
} from 'firebase/firestore';

export type UserImage = {
  imageId: string;
  bustupImgUrl?: string;
  fullbodyImgUrl?: string;
  sideImgUrl?: string;
  smileImgUrl?: string;
  createdAt: Date;
};

export type UserImageFormData = {
  bustupImg?: File;
  fullbodyImg?: File;
  sideImg?: File;
  smileImg?: File;
};

/**
 * Firestore のドキュメントと UserImage オブジェクトの型変換を行います。
 */
export const userImageConverter: FirestoreDataConverter<UserImage> = {
  /**
   * UserImage オブジェクトを Firestore ドキュメントデータへ変換します。
   */
  toFirestore(ui: UserImage): DocumentData {
    return {
      imageId: ui.imageId,
      ...(ui.bustupImgUrl && { bustupImgUrl: ui.bustupImgUrl }),
      ...(ui.fullbodyImgUrl && { fullbodyImgUrl: ui.fullbodyImgUrl }),
      ...(ui.sideImgUrl && { sideImgUrl: ui.sideImgUrl }),
      ...(ui.smileImgUrl && { smileImgUrl: ui.smileImgUrl }),
      createdAt: ui.createdAt || serverTimestamp(),
    };
  },

  /**
   * Firestore ドキュメントデータを UserImage オブジェクトへ変換します。
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): UserImage {
    const data = snapshot.data(options);

    return {
      imageId: data.imageId,
      bustupImgUrl: data.bustupImgUrl,
      fullbodyImgUrl: data.fullbodyImgUrl,
      sideImgUrl: data.sideImgUrl,
      smileImgUrl: data.smileImgUrl,
      createdAt: data.createdAt.toDate(),
    };
  },
};
