import React, {
  useCallback,
  useContext,
  useState,
  FunctionComponent,
  FormEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useAuthenticate, useNetworker } from '../../hooks';
import { labels } from '../../constants';
import { UiContext } from '../../contexts';
import { PATH } from '../../constants';

export const SignInPage: FunctionComponent = () => {
  const networker = useNetworker();
  const { setAuthStatus, setSnackbar } = useContext(UiContext);
  const { signInWithEmail } = useAuthenticate();

  const [mailAddress, setMailAddress] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const signIn = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      await networker(async () => {
        const { success } = await signInWithEmail(mailAddress, password);

        if (success) {
          setAuthStatus('AUTHORIZED');
          setSnackbar(labels.message.signinSuccess);
          navigate(PATH.HOME);
        }
      });
    },
    [mailAddress, password],
  );

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <Box component='form' onSubmit={signIn} noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label={labels.form.email}
            name='email'
            autoComplete='email'
            autoFocus
            value={mailAddress}
            onChange={(e) => setMailAddress(e.target.value)}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label={labels.form.password}
            type='password'
            id='password'
            autoComplete='current-password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            {labels.pages.signIn}
          </Button>

          <Typography variant='caption'>パスワードを忘れましたか？</Typography>
        </Box>
      </Box>
    </Container>
  );
};
