import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { AdminLayout } from '../../components/templates';
import { PageHeader } from '../../components/molecules';
import { labels } from '../../constants';
import { useAgency } from '../../hooks';
import { useParams } from 'react-router-dom';
import { AgencyCommonForm } from '../../components/features/agencies/AgencyCommonForm';

export const AgencyEditPage = () => {
  const { agencyId } = useParams<{ agencyId: string }>();
  const { isFetching, agency } = useAgency(agencyId);

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.agencies.edit} />
      <Box component='main'>
        {isFetching || !agency ? (
          <CircularProgress />
        ) : (
          <AgencyCommonForm agency={agency} method='edit' />
        )}
      </Box>
    </AdminLayout>
  );
};
