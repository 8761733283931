import React, { FunctionComponent } from 'react';
import type { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { PublicUser } from '../../../entities';
import { createUserDetailPath, labels } from '../../../constants';
import { formatDateTime } from '../../../utils/format-date';

interface UserListTableProps {
  publicUsers: PublicUser[];
  usersCount: number;
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  page: number;
  rowsPerPage: number;
}

export const UserListTable: FunctionComponent<UserListTableProps> = (props) => {
  const { publicUsers, usersCount, onPageChange, page, rowsPerPage, ...other } =
    props;

  return (
    <div {...other}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{labels.data.user.name}</TableCell>
            <TableCell>{labels.data.user.createdAt}</TableCell>
            <TableCell>{labels.data.user.updatedAt}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {publicUsers.map((user) => {
            return (
              <TableRow hover key={user.uid}>
                <TableCell>
                  <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <Avatar
                      src={user.bustupImgUrl}
                      sx={{ height: 42, width: 42 }}
                    />
                    <Box
                      sx={{ ml: 1 }}
                      component={Link}
                      to={createUserDetailPath(user.uid)}
                    >
                      {user.name}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>{formatDateTime(user.createdAt)}</TableCell>
                <TableCell>{formatDateTime(user.updatedAt)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={usersCount}
        onPageChange={onPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10]}
      />
    </div>
  );
};
