import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { User } from '../../entities';
import { getUserRef } from '../../infrastructures/firebase';
import { addErrorEventOnSentry } from '../../infrastructures/sentry';

export const useUser = (uid?: string) => {
  const [isFetching, setIsFetching] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (!uid) {
      return;
    }

    const unsubscribe = onSnapshot(
      getUserRef(uid),
      (snapshot) => {
        const data = snapshot.data();

        if (data) {
          setUser(data);
        }

        setIsFetching(false);
      },
      (error) => {
        addErrorEventOnSentry(error);
        setIsFetching(false);
      },
    );

    return unsubscribe;
  }, []);

  return { isFetching, user };
};
