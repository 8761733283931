import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  type DocumentData,
} from 'firebase/firestore';
import { AgencyScore } from './Score';

export type Agency = {
  id: string;
  name: string;
  area: string;
  url: string;
  inheritance: string;
  type: string;
  minAge: number;
  maxAge: number;
  minHeight: number;
  maxHeight: number;
  score: AgencyScore;
  totalScore: number;
  createdAt: Date;
  updatedAt: Date;
};

export type AgencyFormData = Omit<Agency, 'id'>;

// 初期のフォームインスタンス用
export const createInitialAgency = (): Agency => {
  return {
    id: '', // firebase保存時に一意のIDを付与する
    name: '',
    area: '',
    url: '',
    inheritance: '',
    type: '',
    minAge: 0,
    maxAge: 100,
    minHeight: 0,
    maxHeight: 200,
    score: {
      height: 0,
      balance: 0,
      legShape: 0,
      features: 0,
      skinQuality: 0,
      age: 0,
    },
    totalScore: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
};

/**
 * Firestore のドキュメントと Agency オブジェクトの型変換を行います。
 */
export const agencyConverter: FirestoreDataConverter<Agency> = {
  /**
   * Agency オブジェクトを Firestore ドキュメントデータへ変換します。
   */
  toFirestore(agency: Agency): DocumentData {
    return {
      id: agency.id,
      name: agency.name,
      area: agency.area,
      url: agency.url,
      inheritance: agency.inheritance,
      type: agency.type,
      minAge: +agency.minAge,
      maxAge: +agency.maxAge,
      minHeight: +agency.minHeight,
      maxHeight: +agency.maxHeight,
      score: {
        height: +agency.score.height,
        balance: +agency.score.balance,
        legShape: +agency.score.legShape,
        features: +agency.score.features,
        skinQuality: +agency.score.skinQuality,
        age: +agency.score.age,
      },
      totalScore: +agency.totalScore,
      createdAt: agency.createdAt,
      updatedAt: agency.updatedAt,
    };
  },

  /**
   * Firestore ドキュメントデータを Agency オブジェクトへ変換します。
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Agency {
    const data = snapshot.data(options);

    return {
      id: data.id,
      name: data.name,
      area: data.area,
      url: data.url,
      inheritance: data.inheritance,
      type: data.type,
      minAge: data.minAge,
      maxAge: data.maxAge,
      minHeight: data.minHeight,
      maxHeight: data.maxHeight,
      score: data.score,
      totalScore: data.totalScore,
      createdAt: data.createdAt.toDate(),
      updatedAt: data.updatedAt.toDate(),
    };
  },
};
