import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';

export type Advise = {
  content: string;
  url: string;
};

export type Tips = {
  fashionModelScore: {
    height: Advise;
    balance: Advise;
    legShape: Advise;
    limbLength: Advise;
    features: Advise;
    nose: Advise;
    skinQuality: Advise;
    age: Advise;
  };
  commercialModelScore: {
    features: Advise;
    age: Advise;
    balance: Advise;
    legShape: Advise;
    smile: Advise;
    height: Advise;
    nose: Advise;
    skinQuality: Advise;
  };
  updatedAt: Date;
};

export const createInitialTips = (): Tips => {
  return {
    fashionModelScore: {
      height: { content: '', url: '' },
      balance: { content: '', url: '' },
      legShape: { content: '', url: '' },
      limbLength: { content: '', url: '' },
      features: { content: '', url: '' },
      nose: { content: '', url: '' },
      skinQuality: { content: '', url: '' },
      age: { content: '', url: '' },
    },
    commercialModelScore: {
      features: { content: '', url: '' },
      age: { content: '', url: '' },
      balance: { content: '', url: '' },
      legShape: { content: '', url: '' },
      smile: { content: '', url: '' },
      height: { content: '', url: '' },
      nose: { content: '', url: '' },
      skinQuality: { content: '', url: '' },
    },
    updatedAt: new Date(),
  };
};

/**
 * Firestore のドキュメントと User オブジェクトの型変換を行います。
 */
export const tipsConverter: FirestoreDataConverter<Tips> = {
  /**
   * Tips オブジェクトを Firestore ドキュメントデータへ変換します。
   */
  toFirestore(tips: Tips): DocumentData {
    return {
      fashionModelScore: tips.fashionModelScore,
      commercialModelScore: tips.commercialModelScore,
      updatedAt: tips.updatedAt,
    };
  },

  /**
   * Firestore ドキュメントデータを Tips オブジェクトへ変換します。
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Tips {
    const data = snapshot.data(options);

    return {
      fashionModelScore: data.fashionModelScore,
      commercialModelScore: data.commercialModelScore,
      updatedAt: data.updatedAt.toDate(),
    };
  },
};
