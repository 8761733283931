export const labels = {
  siteName: 'モデル診断カルテ 管理サイト',
  siteUrl: 'https://azukariagent.promodelstudio.com/',
  form: {
    email: 'メールアドレス',
    password: 'パスワード',
    passwordConfirm: 'パスワードの確認',
    newsTitle: 'タイトル',
    newsBody: '本文',
    reject: {
      reason: '不備理由',
    },
    errors: {
      required: '入力が必須です',
    },
    actions: {
      cancel: 'キャンセル',
      add: '新規登録',
      edit: '編集する',
      update: 'この内容で保存する',
      save: 'この内容で保存する',
      back: '前の画面に戻る',
      loading: '送信中です',
      delete: '削除する',
    },
  },
  pages: {
    home: 'ホーム',
    signIn: 'ログイン',
    signOut: 'ログアウト',
    tops: 'TOPページ',
    newsList: 'お知らせ一覧',
    agencies: {
      add: '事務所の新規登録',
      edit: '事務所の編集',
      list: '事務所の一覧',
      detail: '事務所の詳細',
    },
    labels: {
      edit: 'ラベルを手動で編集',
    },
    modelCategory: {
      add: 'モデルカテゴリーの新規登録',
      edit: 'モデルカテゴリーの編集',
      list: 'モデルカテゴリーの一覧',
      detail: 'モデルカテゴリーの詳細',
      images: {
        list: 'モデル画像',
        add: 'モデル画像の追加',
      },
    },
    predictions: {
      start: 'ラベルの推論開始',
    },
    tips: {
      edit: '改善ポイントとアドバイス',
    },
    users: {
      add: '応募者の新規登録',
      edit: '応募者の編集',
      list: '応募者の一覧',
      detail: '応募者の詳細',
    },
    userImages: {
      add: '応募者画像の登録',
    },
    userReport: {
      add: 'カルテの作成',
    },
  },
  charts: {
    labels: {
      CM: [
        '顔立ち',
        ['全身', 'バランス'],
        '脚の形',
        '笑顔',
        '身長',
        '鼻',
        '肌質',
        '年齢',
      ],
      FM: [
        '身長',
        ['全身', 'バランス'],
        '脚の形',
        '手足の長さ',
        '顔立ち',
        '鼻',
        '肌質',
        '年齢',
      ],
      Agency: [
        '身長',
        ['全身バ', 'ランス'],
        '脚の形',
        '顔立ち',
        '肌　質',
        '年　齢',
      ],
    },
  },
  data: {
    agency: {
      id: 'ID',
      name: '事務所名',
      area: '所在地',
      url: 'URL',
      inheritance: 'カテゴリー',
      type: '事務所タイプ',
      acceptanceAge: '受け入れ年齢',
      acceptanceHeight: '受け入れ身長',
      minAge: '受け入れ最小年齢',
      maxAge: '受け入れ最大年齢',
      minHeight: '受け入れ最小身長',
      maxHeight: '受け入れ最大身長',
      score: 'スコア',
      totalScore: '総合得点',
      createdAt: '登録日時',
      updatedAt: '更新日時',
      basicInfo: '基本情報',
      chart: 'レーダーチャート',
      photo: '所属モデル画像',
    },
    analyses: {
      headsTall: '頭身',
      legLength: '脚の長さ',
      legShape: '脚の形',
      legBalance: '脚のバランス',
      kneeLength: '膝下の長さ',
      handLength: '手の長さ',
      faceType: '顔の種類',
      eyeType: '目のタイプ',
      eyeShape: '目の形',
      ridgeOfNose: '鼻筋',
      noseSize: '鼻の大きさ',
      lipThickness: '唇の厚さ',
      mouthSize: '口の大きさ',
      noseRatio: '鼻の縦横比',
      noseShapeFront: '鼻の形（正面）',
      noseShapeSide: '鼻の形（横向き）',
      skinType: '肌質',
      mouthShape: '笑顔の形',
      dentition: '歯列',
      malocclusion: '噛み合わせ',
      pelvicTension: '骨盤の張り',
      eyeFeature: '目の特徴',
      asymmetricalFace: '顔の左右対称性',
      noChin: '顎なし',
    },
    labels: {
      predictId: '予測ID',
      headsTall: '頭身',
      legLength: '脚の長さ',
      legShape: '脚の形',
      legBalance: '脚のバランス',
      kneeLength: '膝下の長さ',
      handLength: '手の長さ',
      faceType: '顔の種類',
      eyeType: '目のタイプ',
      eyeShape: '目の形',
      ridgeOfNose: '鼻筋',
      noseSize: '鼻の大きさ',
      lipThickness: '唇の厚さ',
      mouthSize: '口の大きさ',
      noseRatio: '鼻の縦横比',
      noseShapeFront: '鼻の形（正面）',
      noseShapeSide: '鼻の形（横向き）',
      skinType: '肌質',
      mouthShape: '笑顔の形',
      dentition: '歯列',
      malocclusion: '噛み合わせ',
      pelvicTension: '骨盤の張り',
      eyeFeature: '目の特徴',
      asymmetricalFace: '顔の左右対称性',
      noChin: '顎なし',
    },
    news: {
      id: 'ID',
      title: 'タイトル',
      body: '内容',
      createdAt: '作成日時',
    },
    modelCategory: {
      id: 'ID',
      name: 'カテゴリー名',
      inheritance: 'カテゴリー',
      description: '説明文',
      fashionModelScore: 'ファッションモデルスコア',
      commercialModelScore: 'コマーシャルモデルスコア',
      createdAt: '登録日時',
      updatedAt: '更新日時',
      basicInfo: '基本情報',
      raderChart: 'レーダーチャート',
      photo: 'モデル画像',
    },
    modelPhoto: {
      name: 'モデル名（空欄でも構いません）',
      agency: '所属事務所名',
      modelCategory: 'モデルカテゴリー',
      url: '画像URL',
      image: '添付ファイル',
    },
    scores: {
      age: '年齢',
      height: '身長',
      balance: '全身バランス',
      legShape: '脚の形',
      limbLength: '手足の長さ',
      features: '顔立ち',
      nose: '鼻',
      skinQuality: '肌質',
      smile: '笑顔',
    },
    tips: {
      fashionModel: 'ファッションモデル',
      commercialModel: 'コマーシャルモデル',
      title: {
        age: '年齢',
        height: '身長',
        balance: '全身バランス',
        legShape: '脚の形',
        limbLength: '手足の長さ',
        features: '顔立ち',
        nose: '鼻',
        skinQuality: '肌質',
        smile: '笑顔',
      },
    },
    user: {
      uid: 'UID',
      name: '名前',
      height: '身長',
      birthday: '誕生日',
      race: '人種',
      BWHmeasurements: 'スリーサイズ',
      bust: 'バスト',
      waist: 'ウェスト',
      hip: 'ヒップ',
      images: {
        title: '応募者画像',
        bust: 'バストアップ',
        fullbody: '全身',
        side: '真横',
        smile: '笑顔',
      },
      createdAt: '登録日時',
      updatedAt: '更新日時',
      basicInfo: '基本情報',
      analyses: '画像解析',
      labels: 'ラベル',
      reports: 'カルテの生成履歴',
      logs: '変更履歴',
    },
    userImage: {
      uid: 'UID',
      bust: 'バストアップ画像',
      fullbody: '全身画像',
      side: '真横画像',
      smile: '笑顔画像',
    },
    userLog: {
      title: '内容',
      createdAt: '登録日時',
    },
    userReport: {
      reportId: 'ID',
      version: 'バージョン',
      isProcessing: 'ステータス',
      createdAt: '登録日時',
      updatedAt: '更新日時',
      url: 'モデル診断カルテ URL',
    },
    status: {
      isAlreadyAttached: '添付済み',
      isIdentityVerified: '本人確認済み',
    },
  },
  message: {
    signinSuccess: 'ログインに成功しました',
    signinFailure: 'ログインに失敗しました',
    signout: 'ログアウトしました',
    addSuccess: '内容を新規登録しました',
    addFailure: '新規登録に失敗しました',
    updateSuccess: '内容を更新しました',
    updateFailure: '更新に失敗しました',
    uploadSuccess: '画像のアップロードに成功しました',
    uploadFailure: '画像のアップロードに失敗しました',
    fileRemoveSuccess: '画像の削除に成功しました',
    fileRemoveFailure: '画像の削除に失敗しました',
    deleteSuccess: 'データを削除しました',
    deleteFailure: 'データの削除に失敗しました',
    createReportIsProcessing: 'カルテを生成しています...',
    confirmToGoBack: '内容を保存せずに前の画面に戻ってよろしいですか？',
    confirmToDelete: 'このデータを削除してもよろしいですか？',
    confirmPredictionStart:
      'ラベルの推論を開始しますがよろしいですか？手動でラベルを編集する場合は「キャンセル」を押してください',
    startPredicting: 'ラベルの推論を開始しました...',
    debugModeIsWorking: 'デバッグモードで開いています',
  },
};
