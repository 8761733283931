import { useCallback, useContext } from 'react';
import { labels } from '../../constants';
import { UiContext } from '../../contexts';
import { UserImageFormData } from '../../entities';
import { UserImageRepository } from '../../repositories';
import { useNetworker } from '../common/use-networker';

export const useUserImageEditTool = () => {
  const { setSnackbar } = useContext(UiContext);
  const networker = useNetworker();

  const uploadImages = useCallback(
    async (formData: UserImageFormData, uid?: string) => {
      await networker(async () => {
        if (!uid) {
          throw new Error('uid is required');
        }

        // add object to firebase
        await UserImageRepository.add(uid, formData);

        // display success message
        setSnackbar(labels.message.uploadSuccess);
      });
    },
    [],
  );

  return { uploadImages };
};
