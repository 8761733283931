import React, { FunctionComponent, useCallback } from 'react';
import { Avatar, Button, ButtonGroup, Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { labels } from '../../../constants';
import { useModal, useUserEditTool } from '../../../hooks';
import { PublicUser } from '../../../entities';
import { UserReportCreateForm } from '../reports/UserReportCreateForm';
import { PublicUserDomain } from '../../../domains';

type UserTopMenuProps = {
  publicUser: PublicUser;
};

export const UserTopMenu: FunctionComponent<UserTopMenuProps> = ({
  publicUser,
}) => {
  const uid = publicUser.uid;

  // ユーザーの削除アクション
  const { deleteUser } = useUserEditTool();
  const onDelete = useCallback(async () => {
    if (window.confirm(labels.message.confirmToDelete)) {
      await deleteUser(uid);
    }
  }, [uid]);

  // レポート作成モーダルフォームの開閉
  const { isOpen, setOpen, setClose } = useModal(false);

  return (
    <>
      <Grid container justifyContent='space-between' spacing={3}>
        {/* アバターの表示 */}
        <Grid
          item
          sx={{ alignItems: 'center', display: 'flex', overflow: 'hidden' }}
        >
          <Avatar
            src={publicUser.bustupImgUrl}
            sx={{ height: 64, mr: 2, width: 64 }}
          />
          <Typography variant='h5'>{publicUser.name}</Typography>
        </Grid>

        {/* アクションメニュー（画像解析、カルテの作成、削除） */}
        <Grid item>
          <ButtonGroup>
            <Button
              startIcon={<AddIcon fontSize='small' />}
              disabled={!PublicUserDomain.isPublishable(publicUser)}
              onClick={setOpen}
            >
              {labels.pages.userReport.add}
            </Button>
            <Button
              startIcon={<DeleteIcon fontSize='small' />}
              color='error'
              onClick={onDelete}
            >
              {labels.form.actions.delete}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      {/* レポート作成用のモーダルフォーム */}
      <UserReportCreateForm
        isOpen={isOpen}
        onClose={setClose}
        publicUser={publicUser}
      />
    </>
  );
};
