import { onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Tips } from '../../entities';
import { getTipsRef } from '../../infrastructures/firebase';

export const useTips = (): {
  isFetching: boolean;
  tips: Tips | null;
} => {
  const [isFetching, setIsFetching] = useState(true);
  const [tips, setTips] = useState<Tips | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      getTipsRef(),
      (snapshot) => {
        const data = snapshot.data();

        if (data) {
          setTips(data);
        }

        setIsFetching(false);
      },
      (error) => {
        console.error('catch useTips error: ', error.message);
        setIsFetching(false);
      },
    );

    return unsubscribe;
  }, []);

  return { isFetching, tips };
};
