import React from 'react';
import { Card, CardHeader, CircularProgress, Divider } from '@mui/material';
import { PageHeader } from '../components/molecules';
import { AdminLayout } from '../components/templates';
import { labels } from '../constants';
import { InformationListTable } from '../components/features/home/InformationListTable';
import { useAllUserLogs } from '../hooks';

export const TopPage = () => {
  const { isFetching, logs } = useAllUserLogs();

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.home} />
      <Card>
        <CardHeader title='お知らせ' subheader='※ 最新の10件まで取得' />
        <Divider />

        {/* 読み込み中はローディングアイコンを表示 */}
        {isFetching ? (
          <CircularProgress />
        ) : (
          <InformationListTable logs={logs} />
        )}
      </Card>
    </AdminLayout>
  );
};
