import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import CategoryIcon from '@mui/icons-material/Category';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { styled } from '@mui/system';
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { PATH } from '../../constants';
import { labels } from '../../constants';
import { useAuthenticate } from '../../hooks';

export const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.palette.common.black};
  text-decoration: none;

  &.active {
    > * {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
`;

export const Sidebar = (): JSX.Element => {
  const { signOut } = useAuthenticate();

  return (
    <>
      <List>
        <StyledNavLink to={PATH.HOME}>
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={labels.pages.home} />
          </ListItemButton>
        </StyledNavLink>
        <StyledNavLink to={PATH.USER_LIST}>
          <ListItemButton>
            <ListItemIcon>
              <PermIdentityIcon />
            </ListItemIcon>
            <ListItemText primary={labels.pages.users.list} />
          </ListItemButton>
        </StyledNavLink>
        <StyledNavLink to={PATH.AGENCY_LIST}>
          <ListItemButton>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText primary={labels.pages.agencies.list} />
          </ListItemButton>
        </StyledNavLink>
        <StyledNavLink to={PATH.MODEL_CATEGORY_LIST}>
          <ListItemButton>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary={labels.pages.modelCategory.list} />
          </ListItemButton>
        </StyledNavLink>
        <StyledNavLink to={PATH.TIPS_EDIT}>
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={labels.pages.tips.edit} />
          </ListItemButton>
        </StyledNavLink>
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={signOut}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={labels.pages.signOut} />
        </ListItemButton>
      </List>
      <Divider />
    </>
  );
};
