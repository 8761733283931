import React, { FunctionComponent } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { PublicUser } from '../../../entities';
import { labels } from '../../../constants';
import { UserImageCommonModalForm } from '../user-image/UserImageCommonModalForm';
import { useModal } from '../../../hooks';

type UserImagePropertyProps = {
  publicUser: PublicUser | null;
};

export const UserImageProperty: FunctionComponent<UserImagePropertyProps> = ({
  publicUser,
}) => {
  const { isOpen, setOpen, setClose } = useModal(false);
  const isPC = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <>
      <Card>
        <CardHeader title={labels.data.user.images.title} />
        <Divider />

        {publicUser?.bustupImgUrl ||
        publicUser?.fullbodyImgUrl ||
        publicUser?.sideImgUrl ||
        publicUser?.smileImgUrl ? (
          <CardContent>
            <ImageList
              cols={isPC ? 4 : 2}
              gap={24}
              sx={{ marginBottom: 0, marginTop: 0 }}
            >
              {/* bustup image */}
              {publicUser?.bustupImgUrl && (
                <ImageListItem>
                  <img
                    src={publicUser.bustupImgUrl}
                    alt={labels.data.userImage.bust}
                    loading='lazy'
                  />
                  <ImageListItemBar title={labels.data.userImage.bust} />
                </ImageListItem>
              )}

              {/* fullbody image */}
              {publicUser?.fullbodyImgUrl && (
                <ImageListItem>
                  <img
                    src={publicUser.fullbodyImgUrl}
                    alt={labels.data.userImage.fullbody}
                    loading='lazy'
                  />
                  <ImageListItemBar title={labels.data.userImage.fullbody} />
                </ImageListItem>
              )}

              {/* side image */}
              {publicUser?.sideImgUrl && (
                <ImageListItem>
                  <img
                    src={publicUser.sideImgUrl}
                    alt={labels.data.userImage.side}
                    loading='lazy'
                  />
                  <ImageListItemBar title={labels.data.userImage.side} />
                </ImageListItem>
              )}

              {/* smile image */}
              {publicUser?.smileImgUrl && (
                <ImageListItem>
                  <img
                    src={publicUser.smileImgUrl}
                    alt={labels.data.userImage.smile}
                    loading='lazy'
                  />
                  <ImageListItemBar title={labels.data.userImage.smile} />
                </ImageListItem>
              )}
            </ImageList>
          </CardContent>
        ) : null}
        <Divider />
        <CardActions>
          <Button variant='outlined' onClick={setOpen}>
            {labels.pages.userImages.add}
          </Button>
        </CardActions>
      </Card>

      {/* modal form */}
      <Dialog open={isOpen} onClose={setClose}>
        <UserImageCommonModalForm onClose={setClose} />
      </Dialog>
    </>
  );
};
