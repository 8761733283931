import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  serverTimestamp,
  SnapshotOptions,
  type DocumentData,
} from 'firebase/firestore';
import { User } from './User';
import { UserImage } from './UserImage';
import { Label } from './Label';

export type PublicUser = User &
  Omit<UserImage, 'imageId' | 'createdAt'> &
  Partial<Label>;

/**
 * Firestore のドキュメントと PublicUser オブジェクトの型変換を行います。
 */
export const publicUserConverter: FirestoreDataConverter<PublicUser> = {
  /**
   * User オブジェクトを Firestore ドキュメントデータへ変換します。
   */
  toFirestore(user: PublicUser): DocumentData {
    return {
      // User オブジェクトのプロパティをそのまま渡します。
      uid: user.uid,
      name: user.name,
      height: +user.height,
      birthday: user.birthday,
      race: user.race,
      bust: +user.bust,
      waist: +user.waist,
      hip: +user.hip,
      createdAt: user.createdAt || serverTimestamp(),
      updatedAt: serverTimestamp(),

      // UserImageからfunctionsを介してコピー（空の場合もある）
      ...(user.bustupImgUrl && { bustupImgUrl: user.bustupImgUrl }),
      ...(user.fullbodyImgUrl && { fullbodyImgUrl: user.fullbodyImgUrl }),
      ...(user.sideImgUrl && { sideImgUrl: user.sideImgUrl }),
      ...(user.smileImgUrl && { smileImgUrl: user.smileImgUrl }),

      // labelsサブコレクションからfunctionsを介してコピー（空の場合もある）
      ...(user.headsTall && { headsTall: user.headsTall }),
      ...(user.legLength && { legLength: user.legLength }),
      ...(user.legShape && { legShape: user.legShape }),
      ...(user.legBalance && { legBalance: user.legBalance }),
      ...(user.kneeLength && { kneeLength: user.kneeLength }),
      ...(user.handLength && { handLength: user.handLength }),
      ...(user.faceType && { faceType: user.faceType }),
      ...(user.eyeType && { eyeType: user.eyeType }),
      ...(user.eyeShape && { eyeShape: user.eyeShape }),
      ...(user.ridgeOfNose && { ridgeOfNose: user.ridgeOfNose }),
      ...(user.noseSize && { noseSize: user.noseSize }),
      ...(user.lipThickness && { lipThickness: user.lipThickness }),
      ...(user.mouthSize && { mouthSize: user.mouthSize }),
      ...(user.noseRatio && { noseRatio: user.noseRatio }),
      ...(user.noseShapeFront && { noseShapeFront: user.noseShapeFront }),
      ...(user.noseShapeSide && { noseShapeSide: user.noseShapeSide }),
      ...(user.skinType && { skinType: user.skinType }),
      ...(user.mouthShape && { mouthShape: user.mouthShape }),
      ...(user.dentition && { dentition: user.dentition }),
      ...(user.malocclusion && { malocclusion: user.malocclusion }),
      ...(user.pelvicTension && { pelvicTension: user.pelvicTension }),
      ...(user.eyeFeature && { eyeFeature: user.eyeFeature }),
      ...(user.asymmetricalFace && { asymmetricalFace: user.asymmetricalFace }),
      ...(user.noChin && { noChin: user.noChin }),
    };
  },

  /**
   * Firestore ドキュメントデータを User オブジェクトへ変換します。
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): PublicUser {
    const data = snapshot.data(options);

    return {
      // User オブジェクトのプロパティをそのまま渡します。
      uid: data.uid,
      name: data.name,
      height: data.height,
      birthday: data.birthday.toDate(),
      race: data.race,
      bust: data.bust,
      waist: data.waist,
      hip: data.hip,
      createdAt: data.createdAt.toDate(),
      updatedAt: data.updatedAt.toDate(),

      // User Image オブジェクトのプロパティをそのまま渡します。
      bustupImgUrl: data.bustupImgUrl,
      fullbodyImgUrl: data.fullbodyImgUrl,
      sideImgUrl: data.sideImgUrl,
      smileImgUrl: data.smileImgUrl,

      // Label オブジェクトのプロパティをそのまま渡します。
      headsTall: data.headsTall,
      legLength: data.legLength,
      legShape: data.legShape,
      legBalance: data.legBalance,
      kneeLength: data.kneeLength,
      handLength: data.handLength,
      faceType: data.faceType,
      eyeType: data.eyeType,
      eyeShape: data.eyeShape,
      ridgeOfNose: data.ridgeOfNose,
      noseSize: data.noseSize,
      lipThickness: data.lipThickness,
      mouthSize: data.mouthSize,
      noseRatio: data.noseRatio,
      noseShapeFront: data.noseShapeFront,
      noseShapeSide: data.noseShapeSide,
      skinType: data.skinType,
      mouthShape: data.mouthShape,
      dentition: data.dentition,
      malocclusion: data.malocclusion,
      pelvicTension: data.pelvicTension,
      eyeFeature: data.eyeFeature,
      asymmetricalFace: data.asymmetricalFace,
      noChin: data.noChin,
    };
  },
};
