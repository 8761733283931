import React from 'react';
import MainNavigators from './navigators';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ErrorPanel, NetworkProgress, Snackbar } from './components/organisms';
import { adminTheme } from './constants';
import { NetworkManager, UiManager } from './stores';

export const App = (): JSX.Element => (
  <UiManager>
    <NetworkManager>
      <ThemeProvider theme={adminTheme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <NetworkProgress />
        <MainNavigators />
        <ErrorPanel />
        <Snackbar />
      </ThemeProvider>
    </NetworkManager>
  </UiManager>
);
