import React, { FunctionComponent } from 'react';
import {
  Button,
  Divider,
  ImageList,
  ImageListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { Agency } from '../../../entities';
import { RoundButton } from '../../atoms';
import { useModal, usePhotos } from '../../../hooks';

type ModalAgencyDetailProps = {
  agency: Agency;
};

export const ModalAgencyDetail: FunctionComponent<ModalAgencyDetailProps> = ({
  agency,
}) => {
  const { isOpen, setOpen, setClose } = useModal(false);
  const { modelPhotos } = usePhotos({ agencyId: agency.id });

  return (
    <>
      {/* モーダル開閉ボタン */}
      <RoundButton variant='contained' onClick={setOpen}>
        詳しく見る
      </RoundButton>

      {/* イメージに近いモデル一覧を表示するモーダル */}
      <Dialog open={isOpen} onClose={setClose} maxWidth='lg'>
        <DialogTitle>イメージに近いモデル一覧</DialogTitle>
        <Divider />
        <DialogContent>
          <ImageList cols={5} gap={8}>
            {modelPhotos.length ? (
              modelPhotos.map((item) => (
                <ImageListItem key={item.id}>
                  <img src={item.url} loading='lazy' />
                </ImageListItem>
              ))
            ) : (
              <Typography>画像が未設定です</Typography>
            )}
          </ImageList>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={setClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
