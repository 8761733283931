import React, { FunctionComponent } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface RoundButtonProps extends ButtonProps {
  target?: string;
}

const StyledRoundButton = styled(Button)<RoundButtonProps>(
  ({ theme, variant }) => ({
    borderRadius: '2em',
    padding: '.2em 1.2em',
    backgroundColor:
      variant === 'contained'
        ? theme.palette.common.black
        : theme.palette.common.white,
    fontSize: '10px',
    transform: 'scale(0.8)',
  }),
);

export const RoundButton: FunctionComponent<RoundButtonProps> = (props) => {
  return (
    <StyledRoundButton endIcon={<ChevronRightIcon />} {...props}>
      {props.children}
    </StyledRoundButton>
  );
};
