import React, { FunctionComponent, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { labels } from '../../../constants';
import { User, UserFormData } from '../../../entities';
import { useNavigate } from 'react-router-dom';
import { useUserEditTool } from '../../../hooks';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

type UserCommonFormProps = {
  user: User;
  method: 'add' | 'edit';
};

export const UserCommonForm: FunctionComponent<UserCommonFormProps> = ({
  user,
  method,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserFormData>({
    defaultValues: {
      name: user.name,
      height: user.height,
      race: user.race,
      bust: user.bust,
      waist: user.waist,
      hip: user.hip,
      birthday: user.birthday,
      createdAt: user.createdAt,
      updatedAt: new Date(),
    },
  });

  const { createUser, updateUser } = useUserEditTool();

  const onSubmit: SubmitHandler<UserFormData> = async (formData) => {
    if (method === 'add') {
      await createUser(formData);
    } else {
      await updateUser(user.uid, formData);
    }
  };

  const navigate = useNavigate();

  const onClickBack = useCallback(() => {
    if (window.confirm(labels.message.confirmToGoBack)) {
      navigate(-1);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={labels.data.user.basicInfo} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name='name'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={labels.data.user.name}
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message}
                  />
                )}
              />
              <Controller
                name='birthday'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <DatePicker
                    locale={'ja'}
                    dateFormat='yyyy-MM-dd'
                    onChange={field.onChange}
                    selected={field.value}
                    customInput={
                      <TextField
                        fullWidth
                        label={labels.data.user.birthday}
                        error={Boolean(errors?.birthday)}
                        helperText={errors?.birthday?.message}
                      />
                    }
                  />
                )}
              />
              <Controller
                name='height'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={labels.data.user.height}
                    type='number'
                    error={Boolean(errors?.height)}
                    helperText={errors?.height?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>cm</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name='bust'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={labels.data.user.bust}
                    type='number'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>cm</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name='waist'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={labels.data.user.waist}
                    type='number'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>cm</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name='hip'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={labels.data.user.hip}
                    type='number'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>cm</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name='race'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label={labels.data.user.race}
                    error={Boolean(errors?.race)}
                    helperText={errors?.race?.message}
                  >
                    <MenuItem value='日本人'>日本人</MenuItem>
                    <MenuItem value='外国人'>外国人</MenuItem>
                    <MenuItem value='ハーフ'>ハーフ</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          mx: -1,
          mb: -1,
          mt: 3,
        }}
      >
        <Button sx={{ m: 1 }} variant='outlined' onClick={onClickBack}>
          {labels.form.actions.back}
        </Button>

        {isSubmitting ? (
          <Button sx={{ m: 1 }} variant='contained' disabled>
            {labels.form.actions.loading}
          </Button>
        ) : (
          <Button sx={{ m: 1 }} type='submit' variant='contained'>
            {labels.form.actions.save}
          </Button>
        )}
      </Box>
    </form>
  );
};
