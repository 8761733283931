import React, { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { labels } from '../../../constants';
import { Agency, ModelCategory, ModelPhotoFormData } from '../../../entities';
import { usePhotoEditTool } from '../../../hooks';
import { FilePreview } from '../../atoms';

type PhotoUploadModalFormProps = {
  isOpen: boolean;
  onClose: () => void;
  agency?: Agency;
  modelCategory?: ModelCategory;
};

export const PhotoUploadModalForm: FunctionComponent<
  PhotoUploadModalFormProps
> = ({ isOpen, onClose, agency, modelCategory }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    watch,
    reset,
  } = useForm<ModelPhotoFormData>({
    defaultValues: {
      ...(agency && { agencyId: agency.id }),
      ...(modelCategory && { categoryId: modelCategory.id }),
      image: undefined,
    },
  });

  const { addModelPhoto } = usePhotoEditTool();

  // regist data then close modal and reset form
  const onSubmit: SubmitHandler<ModelPhotoFormData> = async (formData) => {
    await addModelPhoto(formData).then(() => {
      onModalClose();
    });
  };

  // modal close and reset form
  const onModalClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onModalClose}>
      <DialogTitle>{labels.data.agency.photo}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name='image'
                control={control}
                rules={{ required: labels.form.errors.required }}
                render={({ field }) => (
                  <FormControl fullWidth margin='normal'>
                    <input
                      type='file'
                      accept='.jpg, .jpeg, .png'
                      onChange={(e) => {
                        if (e.target.files) {
                          field.onChange(e.target.files[0]);
                        }
                      }}
                    />
                    {errors?.image ? (
                      <FormHelperText error>
                        {errors?.image?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />

              <FilePreview file={watch('image')} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isSubmitting ? (
            <Button sx={{ m: 1 }} variant='contained' disabled>
              {labels.form.actions.loading}
            </Button>
          ) : (
            <Button
              sx={{ m: 1 }}
              type='submit'
              variant='contained'
              disabled={!isDirty}
            >
              {labels.form.actions.save}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};
