import { useEffect, useState } from 'react';
import { onSnapshot, orderBy, query } from 'firebase/firestore';
import { PublicUser } from '../../entities';
import { getPublicUserCollection } from '../../infrastructures/firebase';
import { addErrorEventOnSentry } from '../../infrastructures/sentry';

export const usePublicUsers = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [publicUsers, setPublicUsers] = useState<PublicUser[]>([]);

  useEffect(() => {
    const ref = getPublicUserCollection();
    const q = query(ref, orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());

        setPublicUsers(data);
        setIsFetching(false);
      },
      (error) => {
        addErrorEventOnSentry(error);
        setIsFetching(false);
      },
    );

    return unsubscribe;
  }, []);

  return { isFetching, publicUsers };
};
