import React, { FC } from 'react';
import { Card, CardHeader, Divider } from '@mui/material';
import { labels } from '../../../constants';
import { PropertyList } from '../../atoms';
import { ModelCategory } from '../../../entities';
import { formatDateTime } from '../../../utils/format-date';

interface ModelCategoryBasicPropertyProps {
  modelCategory: ModelCategory;
}

export const ModelCategoryBasicProperty: FC<ModelCategoryBasicPropertyProps> = (
  props,
) => {
  const { modelCategory, ...other } = props;

  return (
    <Card {...other}>
      <CardHeader title={labels.data.modelCategory.basicInfo} />
      <Divider />
      <PropertyList.Wrapper>
        <PropertyList.Item
          label={labels.data.modelCategory.name}
          value={modelCategory.name}
        />
        <PropertyList.Item
          label={labels.data.modelCategory.inheritance}
          value={modelCategory.inheritance}
        />
        <PropertyList.Item
          label={labels.data.modelCategory.description}
          value={modelCategory.description}
        />
        <PropertyList.Item
          label={labels.data.modelCategory.createdAt}
          value={formatDateTime(modelCategory.createdAt)}
        />
        <PropertyList.Item
          label={labels.data.modelCategory.updatedAt}
          value={formatDateTime(modelCategory.updatedAt)}
        />
      </PropertyList.Wrapper>
    </Card>
  );
};
