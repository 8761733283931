import { setDoc } from 'firebase/firestore';
import { labels } from '../constants';
import { getPredictionRef } from '../infrastructures/firebase';
import { addErrorEventOnSentry } from '../infrastructures/sentry';
import { createUUID } from '../utils/create-unique-id';

/*
 * 一意のIDをクライアント側で作成しフォームからのPOSTデータに付与しつつ、
 * Firestore上のドキュメントIDにもしつつ、保存する
 */
const add = async (uid: string) => {
  try {
    const predictId = createUUID();
    const ref = getPredictionRef(uid, predictId);

    await setDoc(ref, {
      predictId,
      isProcessing: true,
      isError: false,
      errorCount: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.addFailure);
  }
};

export const PredictionRepository = {
  add,
};
