import React, { useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdminLayout } from '../../components/templates';
import { PageHeader } from '../../components/molecules';
import { createAgencyEditPath, labels } from '../../constants';
import { PATH } from '../../constants';
import { AgencyBasicProperty } from '../../components/features/agencies/AgencyBasicProperty';
import { useAgencyEditTools, useAgency, usePhotos } from '../../hooks';
import { AgencyChartProperty } from '../../components/features/agencies/AgencyChartProperty';
import { ModelPhotoProperty } from '../../components/features/model-photo/ModelPhotoProperty';

type labelType = 'basicInfo' | 'chart' | 'photo';

const tabs: {
  label: string;
  value: labelType;
}[] = [
  { label: labels.data.agency.basicInfo, value: 'basicInfo' },
  { label: labels.data.agency.chart, value: 'chart' },
  { label: labels.data.agency.photo, value: 'photo' },
];

export const AgencyDetailPage = () => {
  const [currentTab, setCurrentTab] = useState<string>('basicInfo');
  const { agencyId } = useParams<{ agencyId: string }>();

  const { isFetching, agency } = useAgency(agencyId);
  const { deleteAgency } = useAgencyEditTools();

  if (!agencyId) {
    return <p>IDを取得できませんでした</p>;
  }

  const { modelPhotos } = usePhotos({ agencyId });

  const handleTabsChange = (event: SyntheticEvent, value: string): void => {
    setCurrentTab(value);
  };

  const onDelete = async () => {
    if (window.confirm(labels.message.confirmToDelete)) {
      await deleteAgency(agencyId);
    }
  };

  return (
    <AdminLayout>
      <PageHeader label={labels.pages.agencies.detail} />

      <Box component='main'>
        <Box sx={{ mb: 4 }}>
          <Link
            to={PATH.AGENCY_LIST}
            color='textPrimary'
            component={RouterLink}
            sx={{ alignItems: 'center', display: 'flex' }}
          >
            <ArrowBackIcon fontSize='small' sx={{ mr: 1 }} />
            <Typography variant='subtitle2'>
              {labels.pages.agencies.list}
            </Typography>
          </Link>
        </Box>
        <Grid container justifyContent='space-between' spacing={3}>
          <Grid
            item
            sx={{
              alignItems: 'center',
              display: 'flex',
              overflow: 'hidden',
            }}
          >
            {isFetching || !agency ? (
              <Skeleton variant='text' width={200} />
            ) : (
              <Typography variant='h4'>{agency.name}</Typography>
            )}
          </Grid>
          <Grid item sx={{ m: -1 }}>
            <Button
              component={RouterLink}
              startIcon={<EditIcon fontSize='small' />}
              sx={{ m: 1 }}
              variant='outlined'
              to={createAgencyEditPath(agencyId)}
            >
              {labels.form.actions.edit}
            </Button>
            <Button
              color='error'
              startIcon={<DeleteIcon fontSize='small' />}
              sx={{ m: 1 }}
              variant='outlined'
              onClick={onDelete}
            >
              {labels.form.actions.delete}
            </Button>
          </Grid>
        </Grid>
        <Tabs
          indicatorColor='primary'
          onChange={handleTabsChange}
          scrollButtons='auto'
          sx={{ mt: 3 }}
          textColor='primary'
          value={currentTab}
          variant='scrollable'
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <Divider />

        {!agency ? (
          <Skeleton variant='rectangular' width='100%' height='300px' />
        ) : (
          <Box sx={{ mt: 3 }}>
            {/* 基本情報タブパネル */}
            {currentTab === 'basicInfo' && (
              <AgencyBasicProperty agency={agency} />
            )}

            {/* チャートパネル */}
            {currentTab === 'chart' && <AgencyChartProperty agency={agency} />}

            {/* 画像タブパネル */}
            {currentTab === 'photo' && (
              <ModelPhotoProperty photos={modelPhotos} agency={agency} />
            )}
          </Box>
        )}
      </Box>
    </AdminLayout>
  );
};
