import React, { FC, ReactNode } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

type PropertyListWrapperProps = {
  children: ReactNode;
};

const PropertyListWrapper: FC<PropertyListWrapperProps> = ({ children }) => {
  return <List disablePadding>{children}</List>;
};

interface PropertyListItemProps extends ListItemProps {
  label: string;
  value?: string | number;
}

const PropertyListItem: FC<PropertyListItemProps> = ({
  children,
  value,
  label,
}) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const align = mdUp ? 'horizontal' : 'vertical';

  return (
    <ListItem sx={{ px: 3, py: 1.5 }} divider>
      <ListItemText
        disableTypography
        primary={
          <Typography
            sx={{ minWidth: align === 'vertical' ? 'inherit' : 180 }}
            variant='subtitle2'
          >
            {label}
          </Typography>
        }
        secondary={
          <Box sx={{ flex: 1, mt: align === 'vertical' ? 0.5 : 0 }}>
            {children || (
              <>
                {value ? (
                  <Typography color='textSecondary' variant='body2'>
                    {value}
                  </Typography>
                ) : (
                  <Typography color='red' variant='body2' fontWeight='bold'>
                    未設定
                  </Typography>
                )}
              </>
            )}
          </Box>
        }
        sx={{
          display: 'flex',
          flexDirection: align === 'vertical' ? 'column' : 'row',
          my: 0,
        }}
      />
    </ListItem>
  );
};

export const PropertyList = {
  Wrapper: PropertyListWrapper,
  Item: PropertyListItem,
};
