import { createUUID } from './create-unique-id';

export const createUniqueFilename = (file: File) => {
  const uuid = createUUID();

  switch (file.type) {
    case 'image/png':
      return `${uuid}.png`;
    case 'image/jpeg':
      return `${uuid}.jpg`;
    case 'image/gif':
      return `${uuid}.gif`;
    default:
      return `${uuid}.jpg`;
  }
};
