import React, { FunctionComponent, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';

type PageHeaderProps = {
  label: string;
  button?: ReactNode;
};

export const PageHeader: FunctionComponent<PageHeaderProps> = ({
  label,
  button,
}) => {
  return (
    <Box mb={4}>
      <Grid container justifyContent='space-between' spacing={3}>
        <Grid item>
          <Typography variant='h1'>{label}</Typography>
        </Grid>
        {button && <Grid item>{button}</Grid>}
      </Grid>
    </Box>
  );
};
