import { onAuthStateChanged, User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { auth as FirebaseAuth } from '../../infrastructures/firebase';

export const useAuth = (): {
  isFetching: boolean;
  auth: User | null;
} => {
  const [isFetching, setisFetching] = useState(true);
  const [auth, setAuth] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FirebaseAuth, (authenticated) => {
      if (authenticated) {
        setAuth(authenticated);
      }
      setisFetching(false);
    });

    return unsubscribe;
  }, [auth]);

  return { isFetching, auth };
};
