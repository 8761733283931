import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { createModelCategoryDetailPath, labels, PATH } from '../../constants';
import { UiContext } from '../../contexts';
import { ModelCategoryFormData } from '../../entities';
import { ModelCategoryRepository } from '../../repositories';
import { useNetworker } from '../common/use-networker';

export const useModelCategoryEditTools = () => {
  const { setSnackbar } = useContext(UiContext);
  const networker = useNetworker();
  const navigate = useNavigate();

  const addModelCategory = useCallback(
    async (formData: ModelCategoryFormData) => {
      await networker(async () => {
        // add object to firebase
        await ModelCategoryRepository.add(formData);

        // display success message
        setSnackbar(labels.message.addSuccess);

        // redirect
        navigate(PATH.MODEL_CATEGORY_LIST);
      });
    },
    [],
  );

  const updateModelCategory = useCallback(
    async (categoryId: string, formData: ModelCategoryFormData) => {
      await networker(async () => {
        // add object to firebase
        await ModelCategoryRepository.set(categoryId, formData);

        // display success message
        setSnackbar(labels.message.updateSuccess);

        // redirect
        navigate(createModelCategoryDetailPath(categoryId));
      });
    },
    [],
  );

  const deleteModelCategory = useCallback(async (categoryId: string) => {
    await networker(async () => {
      // remove data from firebase
      await ModelCategoryRepository.remove(categoryId);

      // display success message
      setSnackbar(labels.message.deleteSuccess);

      // redirect
      navigate(PATH.MODEL_CATEGORY_LIST);
    });
  }, []);

  return { addModelCategory, updateModelCategory, deleteModelCategory };
};
