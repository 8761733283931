export const HEADS_TALL_ITEMS = {
  a: '8.5頭身',
  b: '8頭身',
  c: '7.5頭身',
  d: '7頭身',
  e: '6.5頭身',
  f: '6頭身',
};

export const LEG_LENGTH_ITEMS = {
  a: '股下から膝 < 膝下から足首',
  b: '股下から膝 = 膝下から足首',
  c: '股下から膝 > 膝下から足首',
};

export const LEG_SHAPE_ITEMS = {
  a: 'O脚',
  b: 'X脚',
  c: 'XO脚',
  d: '真っ直ぐ',
};

export const LEG_BALANCE_ITEMS = {
  a: '太ももが太い',
  b: 'ふくらはぎが太い',
  c: '足首が太い',
  d: '太もも:ふくらはぎ:足首=5:3:2',
};

export const KNEE_LENGTH_ITEMS = {
  a: '膝下 > 身長 / 2',
  b: '膝下 = 身長 / 2',
  c: '膝下 < 身長 / 2',
};

export const HAND_LENGTH_ITEMS = {
  a: '手首が股下ラインより下',
  b: '手首が股下ラインと同じ',
  c: '手首が股下ラインより上',
};

export const FACE_TYPE_ITEMS = {
  a: '菱型',
  b: '卵型',
  c: '面長型',
  d: '三角型',
  e: 'ベース型',
};

export const EYE_TYPE_ITEMS = {
  a: '一重',
  b: '二重',
  c: '奥二重',
};

export const EYE_SHAPE_ITEMS = {
  a: '丸い',
  c: 'アーモンド型',
  d: '切長',
};

export const RIDGE_OF_NOSE_ITEMS = {
  a: '細い',
  b: '普通',
  c: '太い',
};

export const NOSE_SIZE_ITEMS = {
  a: '小さい',
  b: '普通',
  c: '大きい',
};

export const LIP_THICKNESS_ITEMS = {
  a: '薄い',
  b: '普通',
  c: '厚い',
};

export const MOUTH_SIZE_ITEMS = {
  a: '小さい',
  b: '普通',
  c: '大きい',
};

export const NOSE_RATIO_ITEMS = {
  a: '縦横比=100:64',
  b: '縦が短い',
  c: '横が短い',
};

export const NOSE_SHAPE_FRONT_ITEMS = {
  a: '湾曲鼻',
  b: '団子鼻',
  c: '豚鼻',
  d: '該当なし',
};

export const NOSE_SHAPE_SIDE_ITEMS = {
  a: 'Eラインのバランス良好',
  b: 'わし鼻',
  c: '矢印鼻',
  d: '該当なし',
};

export const SKIN_TYPE_ITEMS = {
  a: '色ムラがある',
  b: '傷跡がある',
  c: '吹き出物がある',
  d: '先天的な色素沈着',
  e: '黒子が多い',
  f: '該当なし',
};

export const MOUTH_SHAPE_ITEMS = {
  a: '口角が左右等しく上がる',
  b: '上の歯8~10本が見える',
  c: '歯茎が見えない',
  d: '上唇：下唇＝1：1.6',
  e: '口角の幅が両目の外端',
  f: '該当なし',
};

export const DENTITION_ITEMS = {
  a: '歯列の乱れ',
  b: '八重歯',
  c: 'すきっ歯',
  d: '該当なし',
};

export const MALOCCLUSION_ITEMS = {
  a: '出っ歯',
  b: '受け口',
  c: '該当なし',
};

export const PELVIC_TENSION_ITEMS = {
  a: 'あり',
  b: '該当なし',
};

export const EYE_FEATURE_ITEMS = {
  a: '斜視',
  b: '三白眼',
  c: '該当なし',
};

export const ASYMMETRICAL_FACE_ITEMS = {
  a: '顔が左右非対称',
  b: '該当なし',
};

export const NO_CHIN_ITEMS = {
  a: '顎なし',
  b: '該当なし',
};
