import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const valueLabelFormat = (value: number) => {
  return `${value} cm`;
};

type UserHeightSliderProps = {
  value: number;
  min: number;
  max: number;
};

export const UserHeightSlider: FC<UserHeightSliderProps> = ({
  value,
  min,
  max,
}) => {
  return (
    <Box sx={{ width: 100 }}>
      <Slider
        value={value}
        valueLabelDisplay='on'
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={valueLabelFormat}
        marks={[
          { value: min, label: `${min}cm` },
          { value: max, label: `${max}cm` },
        ]}
        min={min}
        max={max}
        size='small'
        disabled
      />
    </Box>
  );
};
