import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserDetailPath, labels, PATH } from '../../constants';
import { UiContext } from '../../contexts';
import { UserFormData } from '../../entities';
import { UserRepository } from '../../repositories';
import { useNetworker } from '../common/use-networker';

export const useUserEditTool = () => {
  const { setSnackbar } = useContext(UiContext);
  const networker = useNetworker();
  const navigate = useNavigate();

  const createUser = useCallback(async (formData: UserFormData) => {
    await networker(async () => {
      // add object to firebase
      await UserRepository.add(formData);

      // display success message
      setSnackbar(labels.message.addSuccess);

      // redirect
      navigate(PATH.USER_LIST);
    });
  }, []);

  const updateUser = useCallback(
    async (uid: string, formData: UserFormData) => {
      await networker(async () => {
        // add object to firebase
        await UserRepository.set(uid, formData);

        // display success message
        setSnackbar(labels.message.updateSuccess);

        // redirect
        navigate(createUserDetailPath(uid));
      });
    },
    [],
  );

  const deleteUser = useCallback(async (uid: string) => {
    await networker(async () => {
      // remove data from firebase
      await UserRepository.remove(uid);

      // display success message
      setSnackbar(labels.message.deleteSuccess);

      // redirect
      navigate(PATH.USER_LIST);
    });
  }, []);

  return { createUser, updateUser, deleteUser };
};
