import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';

export const formatDate = (date: Date): string => {
  return format(date, 'YYY年M月d日', { locale: ja });
};

export const formatDateTime = (date: Date): string => {
  return format(date, 'YYY年M月d日 HH:mm', { locale: ja });
};
