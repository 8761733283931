import React from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { PATH } from '../constants/path';

export const NotFoundPage = () => (
  <Box
    sx={{
      bgcolor: 'background.paper',
      pt: 8,
      pb: 6,
    }}
  >
    <Container maxWidth='sm'>
      <Typography variant='h1' align='center' color='text.primary' gutterBottom>
        Page Not Found
      </Typography>
      <Typography variant='h5' align='center' color='text.secondary' paragraph>
        URLに誤りがあるか存在しないURLのようです。
      </Typography>
      <Stack sx={{ pt: 4 }} direction='row' spacing={2} justifyContent='center'>
        <Button variant='contained' component={Link} to={PATH.HOME}>
          ホームに戻る
        </Button>
      </Stack>
    </Container>
  </Box>
);
