import React, { FC } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { labels } from '../../../constants';
import { useModal, usePhotoEditTool } from '../../../hooks';
import { Agency, ModelCategory, ModelPhoto } from '../../../entities';
import { PhotoUploadModalForm } from '../model-photo/PhotoUploadModalForm';
import { formatDateTime } from '../../../utils/format-date';

type ModelPhotoPropertyProps = {
  photos: ModelPhoto[];
  agency?: Agency;
  modelCategory?: ModelCategory;
};

export const ModelPhotoProperty: FC<ModelPhotoPropertyProps> = ({
  photos,
  agency,
  modelCategory,
}) => {
  const { isOpen, setOpen, setClose } = useModal(false);

  const { deleteModelPhoto } = usePhotoEditTool();

  const onDelete = async (photoId: string) => {
    if (window.confirm(labels.message.confirmToDelete)) {
      await deleteModelPhoto(photoId);
    }
  };

  return (
    <>
      {/* 事務所に所属するモデルの画像一覧 */}
      <Card>
        <CardHeader title={labels.pages.modelCategory.images.list} />
        <Divider />

        {photos.length ? (
          <>
            <CardContent>
              <ImageList cols={6} gap={6}>
                {photos.map((photo) => (
                  <ImageListItem key={photo.id}>
                    <img src={photo.url} loading='lazy' />
                    <ImageListItemBar
                      title={formatDateTime(photo.createdAt)}
                      actionIcon={
                        <IconButton
                          sx={{ color: 'common.white' }}
                          onClick={async () => onDelete(photo.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </CardContent>
            <Divider />
          </>
        ) : null}

        <CardActions>
          <Button variant='outlined' onClick={setOpen}>
            {labels.pages.modelCategory.images.add}
          </Button>
        </CardActions>
      </Card>

      {/* 画像アップロードモーダルフォーム */}
      <PhotoUploadModalForm
        isOpen={isOpen}
        onClose={setClose}
        agency={agency}
        modelCategory={modelCategory}
      />
    </>
  );
};
