import React, { FunctionComponent } from 'react';
import type { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { ModelCategory } from '../../../entities';
import { createModelCategoryDetailPath, labels } from '../../../constants';
import { formatDateTime } from '../../../utils/format-date';

interface ModelCategoryListTableProps {
  items: ModelCategory[];
  itemsCount: number;
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  page: number;
  rowsPerPage: number;
}

export const ModelCategoryListTable: FunctionComponent<
  ModelCategoryListTableProps
> = (props) => {
  const { items, itemsCount, onPageChange, page, rowsPerPage, ...other } =
    props;

  return (
    <div {...other}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{labels.data.modelCategory.name}</TableCell>
            <TableCell>{labels.data.modelCategory.createdAt}</TableCell>
            <TableCell>{labels.data.modelCategory.updatedAt}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((category) => {
            return (
              <TableRow hover key={category.id}>
                <TableCell>
                  <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <Box
                      component={Link}
                      to={createModelCategoryDetailPath(category.id)}
                    >
                      {category.name}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>{formatDateTime(category.createdAt)}</TableCell>
                <TableCell>{formatDateTime(category.updatedAt)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={itemsCount}
        onPageChange={onPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
      />
    </div>
  );
};
