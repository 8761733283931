import React, { useContext, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { UiContext } from '../contexts';
import { useAuth } from '../hooks';

export const Loading = (): JSX.Element => {
  const { setAuthStatus } = useContext(UiContext);
  const { isFetching, auth } = useAuth();

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if (auth) {
      setAuthStatus('AUTHORIZED');
      return;
    }

    setAuthStatus('UN_AUTHORIZED');
  }, [isFetching, setAuthStatus]);

  return (
    <Backdrop
      open={true}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};
