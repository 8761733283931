import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { createAgencyDetailPath, labels, PATH } from '../../constants';
import { UiContext } from '../../contexts';
import { AgencyFormData } from '../../entities';
import { AgencyRepository } from '../../repositories';
import { useNetworker } from '../common/use-networker';

export const useAgencyEditTools = () => {
  const { setSnackbar } = useContext(UiContext);
  const networker = useNetworker();
  const navigate = useNavigate();

  const addAgency = useCallback(async (formData: AgencyFormData) => {
    await networker(async () => {
      // add object to firebase
      await AgencyRepository.add(formData);

      // display success message
      setSnackbar(labels.message.addSuccess);

      // redirect
      navigate(PATH.AGENCY_LIST);
    });
  }, []);

  const updateAgency = useCallback(
    async (agencyId: string, formData: AgencyFormData) => {
      await networker(async () => {
        // add object to firebase
        await AgencyRepository.set(agencyId, formData);

        // display success message
        setSnackbar(labels.message.updateSuccess);

        // redirect
        navigate(createAgencyDetailPath(agencyId));
      });
    },
    [],
  );

  const deleteAgency = useCallback(async (agencyId: string) => {
    await networker(async () => {
      // remove data from firebase
      await AgencyRepository.remove(agencyId);

      // display success message
      setSnackbar(labels.message.deleteSuccess);

      // redirect
      navigate(PATH.AGENCY_LIST);
    });
  }, []);

  return { addAgency, updateAgency, deleteAgency };
};
