import { setDoc } from 'firebase/firestore';
import { labels } from '../constants';
import { UserImage, UserImageFormData } from '../entities';
import { getUserImageRef } from '../infrastructures/firebase';
import { addErrorEventOnSentry } from '../infrastructures/sentry';
import { createUniqueFilename } from '../utils/create-unique-filename';
import { createUUID } from '../utils/create-unique-id';
import { StorageRepository } from './storage';

/*
 * 一意のIDをクライアント側で作成しフォームからのPOSTデータに付与しつつ、
 * Firestore上のドキュメントIDにもしつつ、保存する
 */
const add = async (uid: string, formData: UserImageFormData) => {
  try {
    const imageId = createUUID();
    const ref = getUserImageRef(uid, imageId);
    const data: Omit<UserImage, 'createdAt'> = { imageId };

    if (formData.bustupImg) {
      const bustupImgUrl = await StorageRepository.add(
        `photos/users/${createUniqueFilename(formData.bustupImg)}`,
        formData.bustupImg,
      );
      data.bustupImgUrl = bustupImgUrl;
    }

    if (formData.fullbodyImg) {
      const fullbodyImgUrl = await StorageRepository.add(
        `photos/users/${createUniqueFilename(formData.fullbodyImg)}`,
        formData.fullbodyImg,
      );
      data.fullbodyImgUrl = fullbodyImgUrl;
    }

    if (formData.sideImg) {
      const sideImgUrl = await StorageRepository.add(
        `photos/users/${createUniqueFilename(formData.sideImg)}`,
        formData.sideImg,
      );
      data.sideImgUrl = sideImgUrl;
    }

    if (formData.smileImg) {
      const smileImgUrl = await StorageRepository.add(
        `photos/users/${createUniqueFilename(formData.smileImg)}`,
        formData.smileImg,
      );
      data.smileImgUrl = smileImgUrl;
    }

    await setDoc(ref, data);
  } catch (e) {
    addErrorEventOnSentry(e);
    throw new Error(labels.message.addFailure);
  }
};

export const UserImageRepository = {
  add,
};
