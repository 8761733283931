import React, { FunctionComponent } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import { Report } from '../../../entities';
import { formatDateTime } from '../../../utils/format-date';
import { createUserReportPath, labels } from '../../../constants';
import { useParams } from 'react-router-dom';

interface UserListTableProps {
  reports: Report[];
}

export const UserReportsTable: FunctionComponent<UserListTableProps> = ({
  reports,
}) => {
  const { uid } = useParams<{ uid: string }>();

  if (!uid) {
    return <p>uidが取得できませんでした</p>;
  }

  return (
    <Card>
      <CardHeader title={labels.data.user.reports} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{labels.data.userReport.createdAt}</TableCell>
            <TableCell>{labels.data.userReport.version}</TableCell>
            <TableCell>{labels.data.userReport.url}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report) => {
            const userReportPath = createUserReportPath(uid, report.reportId);

            return (
              <TableRow key={report.reportId}>
                <TableCell>{formatDateTime(report.createdAt)}</TableCell>
                <TableCell>{report.version}</TableCell>

                {/* レポート作成中はローディングアイコンを表示 */}
                <TableCell>
                  {report.isProcessing ? (
                    <CircularProgress size={24} />
                  ) : (
                    <>
                      <Button
                        href={userReportPath}
                        target='_blank'
                        startIcon={<AddLinkIcon fontSize='small' />}
                        size='small'
                      >
                        カルテを開く
                      </Button>
                      <Button
                        href={`${userReportPath}?mode=debug`}
                        target='_blank'
                        startIcon={<PrivacyTipIcon fontSize='small' />}
                        size='small'
                      >
                        デバッグモード
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};
