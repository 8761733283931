import {
  DocumentData,
  collection,
  CollectionReference,
  collectionGroup,
  doc,
} from 'firebase/firestore';

import { firestore } from './init';

import {
  agencyConverter,
  LabelConverter,
  modelCategoryConverter,
  modelPhotoConverter,
  publicUserConverter,
  userReportConverter,
  tipsConverter,
  userConverter,
  userImageConverter,
  userLogConverter,
} from '../../entities';
import { predictionConverter } from '../../entities/Prediction';

// This is just a helper to add the type to the db responses
export const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};

// collection refs
export const userCollection =
  createCollection('users').withConverter(userConverter);

export const configCollection = createCollection('config');

export const getAgencyCollection =
  createCollection('agencies').withConverter(agencyConverter);

export const getLabelCollection = (uid: string) => {
  return createCollection(`users/${uid}/labels`).withConverter(LabelConverter);
};

export const getPublicUserCollection = () => {
  return createCollection('public-users').withConverter(publicUserConverter);
};

export const getUserReportCollection = (uid: string) => {
  return createCollection(`users/${uid}/reports`).withConverter(
    userReportConverter,
  );
};

export const getUserImageCollection = (uid: string) => {
  return createCollection(`users/${uid}/images`).withConverter(
    userImageConverter,
  );
};

export const getUserLogCollection = (uid: string) => {
  return createCollection(`users/${uid}/logs`).withConverter(userLogConverter);
};

export const getModelCategoryCollection = createCollection(
  'modelCategories',
).withConverter(modelCategoryConverter);

export const getUserPredictionCollection = (uid: string) => {
  return createCollection(`users/${uid}/predictions`).withConverter(
    predictionConverter,
  );
};

// doc refs

export const getAgencyRef = (agencyId: string) => {
  return doc(getAgencyCollection, agencyId);
};

export const getLabelRef = (uid: string, labelId: string) => {
  return doc(getLabelCollection(uid), labelId);
};

export const getModelCategoryRef = (categoryId: string) => {
  return doc(getModelCategoryCollection, categoryId);
};

export const modelPhotoCollection =
  createCollection('photos').withConverter(modelPhotoConverter);

export const getModelPhotoRef = (photoId: string) => {
  return doc(modelPhotoCollection, photoId);
};

export const getPredictionRef = (uid: string, predictId: string) => {
  return doc(getUserPredictionCollection(uid), predictId);
};

export const getTipsRef = () => {
  return doc(configCollection.withConverter(tipsConverter), 'tips');
};

export const getUserReportRef = (uid: string, reportId: string) => {
  return doc(getUserReportCollection(uid), reportId);
};

export const getPublicUserRef = (uid: string) => {
  return doc(getPublicUserCollection(), uid);
};

export const getUserRef = (uid: string) => {
  return doc(userCollection, uid);
};

export const getUserImageRef = (uid: string, imageId: string) => {
  return doc(getUserImageCollection(uid), imageId);
};

// コレクショングループ
export const getUserLogCollectionGroup = () => {
  return collectionGroup(firestore, 'logs').withConverter(userLogConverter);
};
