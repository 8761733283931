import React, { FunctionComponent } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartDataLabels,
);

type UserRaderChartProps = {
  labels: Array<string | string[]>;
  data?: number[];
  color?: 'primary' | 'secondary';
  labelDisplay?: boolean;
};

const primaryBorderColor = {
  backgroundColor: 'rgba(241, 158, 194, 0.2)',
  borderColor: 'rgba(241, 158, 194, 1.0)',
};

const secondaryBorderColor = {
  backgroundColor: 'rgba(84, 195, 241, 0.2)',
  borderColor: 'rgba(84, 195, 241, 1.0)',
};

export const UserRaderChart: FunctionComponent<UserRaderChartProps> = ({
  labels,
  data,
  color = 'primary',
  labelDisplay = false,
}) => {
  return (
    <Radar
      data={{
        labels: labels,
        datasets: [
          {
            data: data,
            borderWidth: 1,
            ...(color === 'primary'
              ? { ...primaryBorderColor }
              : { ...secondaryBorderColor }),
          },
        ],
      }}
      options={{
        scales: {
          r: {
            max: 10,
            min: 0,
            ticks: {
              display: false, // 目盛りの表示非表示
              stepSize: 2,
            },
          },
        },
        plugins: {
          datalabels: {
            display: labelDisplay,
            anchor: 'end',
            align: 'bottom',
            formatter: (value) => `${value || 0}点`,
          },
        },
      }}
    />
  );
};
