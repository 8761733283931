import React, { FC, useCallback } from 'react';
import { Button, Card, CardActions, CardHeader, Divider } from '@mui/material';
import { PropertyList } from '../../atoms';
import { labels } from '../../../constants';
import { PublicUser } from '../../../entities';
import EditIcon from '@mui/icons-material/Edit';
import {
  ASYMMETRICAL_FACE_ITEMS,
  DENTITION_ITEMS,
  EYE_FEATURE_ITEMS,
  EYE_SHAPE_ITEMS,
  EYE_TYPE_ITEMS,
  FACE_TYPE_ITEMS,
  HAND_LENGTH_ITEMS,
  HEADS_TALL_ITEMS,
  KNEE_LENGTH_ITEMS,
  LEG_BALANCE_ITEMS,
  LEG_LENGTH_ITEMS,
  LEG_SHAPE_ITEMS,
  LIP_THICKNESS_ITEMS,
  MALOCCLUSION_ITEMS,
  MOUTH_SHAPE_ITEMS,
  MOUTH_SIZE_ITEMS,
  NOSE_RATIO_ITEMS,
  NOSE_SHAPE_FRONT_ITEMS,
  NOSE_SHAPE_SIDE_ITEMS,
  NOSE_SIZE_ITEMS,
  NO_CHIN_ITEMS,
  PELVIC_TENSION_ITEMS,
  RIDGE_OF_NOSE_ITEMS,
  SKIN_TYPE_ITEMS,
} from '../../../constants/items';
import { LabelModalForm } from '../label/LabelModalForm';
import { useModal, usePrediction, usePredictionEditTool } from '../../../hooks';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { PublicUserDomain } from '../../../domains';

type UserLabelsProps = {
  publicUser: PublicUser;
};

export const UserLabelsProperty: FC<UserLabelsProps> = ({ publicUser }) => {
  const { isOpen, setOpen, setClose } = useModal(false);
  const uid = publicUser.uid;

  const { startPredicting } = usePredictionEditTool();
  const { isPredicting } = usePrediction(uid);
  const isExistImages = PublicUserDomain.isExistImages(publicUser);

  // `onStartPredicting` は推論プロセスを開始するための非同期コールバック関数です。
  const onStartPredicting = useCallback(async () => {
    // すべての画像が登録されていない場合、警告を表示し、処理を終了します。
    if (!isExistImages) {
      alert('未登録の画像があるため解析できません');
      return;
    }
    // 推論がすでに実行中の場合、警告を表示し、処理を終了します。
    if (isPredicting) {
      alert('推論中です');
      return;
    }
    // ユーザーが推論開始の確認ダイアログでOKを選択した場合、推論を開始します。
    if (confirm(labels.message.confirmPredictionStart)) {
      await startPredicting(uid);
    }
  }, [isPredicting, isExistImages, uid]);

  return (
    <>
      <Card>
        <CardHeader title={labels.data.user.labels} />
        <Divider />
        <PropertyList.Wrapper>
          <PropertyList.Item
            value={
              HEADS_TALL_ITEMS[
                publicUser.headsTall as keyof typeof HEADS_TALL_ITEMS
              ]
            }
            label={labels.data.analyses.headsTall}
          />

          <PropertyList.Item
            value={
              LEG_LENGTH_ITEMS[
                publicUser.legLength as keyof typeof LEG_LENGTH_ITEMS
              ]
            }
            label={labels.data.analyses.legLength}
          />

          <PropertyList.Item
            value={
              LEG_SHAPE_ITEMS[
                publicUser.legShape as keyof typeof LEG_SHAPE_ITEMS
              ]
            }
            label={labels.data.analyses.legShape}
          />

          {publicUser?.legBalance && (
            <PropertyList.Item
              label={labels.data.analyses.legBalance}
              value={publicUser.legBalance
                .map(
                  (key) =>
                    LEG_BALANCE_ITEMS[key as keyof typeof LEG_BALANCE_ITEMS],
                )
                .join(', ')}
            />
          )}

          <PropertyList.Item
            value={
              KNEE_LENGTH_ITEMS[
                publicUser.kneeLength as keyof typeof KNEE_LENGTH_ITEMS
              ]
            }
            label={labels.data.analyses.kneeLength}
          />

          <PropertyList.Item
            value={
              HAND_LENGTH_ITEMS[
                publicUser.handLength as keyof typeof HAND_LENGTH_ITEMS
              ]
            }
            label={labels.data.analyses.handLength}
          />

          {publicUser?.faceType && (
            <PropertyList.Item
              value={
                FACE_TYPE_ITEMS[
                  publicUser.faceType as keyof typeof FACE_TYPE_ITEMS
                ]
              }
              label={labels.data.analyses.faceType}
            />
          )}

          <PropertyList.Item
            value={
              EYE_TYPE_ITEMS[publicUser.eyeType as keyof typeof EYE_TYPE_ITEMS]
            }
            label={labels.data.analyses.eyeType}
          />

          <PropertyList.Item
            value={
              EYE_SHAPE_ITEMS[
                publicUser.eyeShape as keyof typeof EYE_SHAPE_ITEMS
              ]
            }
            label={labels.data.analyses.eyeShape}
          />

          <PropertyList.Item
            value={
              RIDGE_OF_NOSE_ITEMS[
                publicUser.ridgeOfNose as keyof typeof RIDGE_OF_NOSE_ITEMS
              ]
            }
            label={labels.data.analyses.ridgeOfNose}
          />

          <PropertyList.Item
            value={
              NOSE_SIZE_ITEMS[
                publicUser.noseSize as keyof typeof NOSE_SIZE_ITEMS
              ]
            }
            label={labels.data.analyses.noseSize}
          />

          <PropertyList.Item
            value={
              LIP_THICKNESS_ITEMS[
                publicUser.lipThickness as keyof typeof LIP_THICKNESS_ITEMS
              ]
            }
            label={labels.data.analyses.lipThickness}
          />

          <PropertyList.Item
            value={
              MOUTH_SIZE_ITEMS[
                publicUser.mouthSize as keyof typeof MOUTH_SIZE_ITEMS
              ]
            }
            label={labels.data.analyses.mouthSize}
          />

          <PropertyList.Item
            label={labels.data.analyses.noseRatio}
            value={
              NOSE_RATIO_ITEMS[
                publicUser.noseRatio as keyof typeof NOSE_RATIO_ITEMS
              ]
            }
          />

          <PropertyList.Item
            label={labels.data.analyses.noseShapeFront}
            value={
              publicUser?.noseShapeFront
                ? publicUser.noseShapeFront
                    .map(
                      (key) =>
                        NOSE_SHAPE_FRONT_ITEMS[
                          key as keyof typeof NOSE_SHAPE_FRONT_ITEMS
                        ],
                    )
                    .join(', ')
                : ''
            }
          />

          <PropertyList.Item
            label={labels.data.analyses.noseShapeSide}
            value={
              publicUser?.noseShapeSide
                ? publicUser.noseShapeSide
                    .map(
                      (key) =>
                        NOSE_SHAPE_SIDE_ITEMS[
                          key as keyof typeof NOSE_SHAPE_SIDE_ITEMS
                        ],
                    )
                    .join(', ')
                : ''
            }
          />

          <PropertyList.Item
            label={labels.data.analyses.skinType}
            value={
              publicUser?.skinType
                ? publicUser.skinType
                    .map(
                      (key) =>
                        SKIN_TYPE_ITEMS[key as keyof typeof SKIN_TYPE_ITEMS],
                    )
                    .join(', ')
                : ''
            }
          />

          <PropertyList.Item
            label={labels.data.analyses.mouthShape}
            value={
              publicUser?.mouthShape
                ? publicUser.mouthShape
                    .map(
                      (key) =>
                        MOUTH_SHAPE_ITEMS[
                          key as keyof typeof MOUTH_SHAPE_ITEMS
                        ],
                    )
                    .join(', ')
                : ''
            }
          />

          <PropertyList.Item
            label={labels.data.analyses.dentition}
            value={
              DENTITION_ITEMS[
                publicUser.dentition as keyof typeof DENTITION_ITEMS
              ]
            }
          />

          <PropertyList.Item
            label={labels.data.analyses.malocclusion}
            value={
              MALOCCLUSION_ITEMS[
                publicUser.malocclusion as keyof typeof MALOCCLUSION_ITEMS
              ]
            }
          />

          <PropertyList.Item
            label={labels.data.analyses.pelvicTension}
            value={
              PELVIC_TENSION_ITEMS[
                publicUser.pelvicTension as keyof typeof PELVIC_TENSION_ITEMS
              ]
            }
          />

          <PropertyList.Item
            label={labels.data.analyses.eyeFeature}
            value={
              EYE_FEATURE_ITEMS[
                publicUser.eyeFeature as keyof typeof EYE_FEATURE_ITEMS
              ]
            }
          />

          <PropertyList.Item
            label={labels.data.analyses.asymmetricalFace}
            value={
              ASYMMETRICAL_FACE_ITEMS[
                publicUser.asymmetricalFace as keyof typeof ASYMMETRICAL_FACE_ITEMS
              ]
            }
          />

          <PropertyList.Item
            label={labels.data.analyses.noChin}
            value={
              NO_CHIN_ITEMS[publicUser.noChin as keyof typeof NO_CHIN_ITEMS]
            }
          />
        </PropertyList.Wrapper>
        <CardActions>
          <Button
            startIcon={<EditIcon fontSize='small' />}
            variant='outlined'
            onClick={setOpen}
          >
            {labels.pages.labels.edit}
          </Button>

          <Button
            startIcon={<PersonSearchIcon fontSize='small' />}
            variant='contained'
            disabled={!isExistImages || isPredicting}
            onClick={onStartPredicting}
          >
            {labels.pages.predictions.start}
          </Button>
        </CardActions>
      </Card>

      {/* 編集モーダルフォーム */}
      <LabelModalForm
        isOpen={isOpen}
        onClose={setClose}
        publicUser={publicUser}
      />
    </>
  );
};
