import React, { FunctionComponent, useCallback, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createInitialErrorState, UiContext } from '../../contexts';

export const ErrorPanel: FunctionComponent = () => {
  const { error, setError } = useContext(UiContext);

  const onClose = useCallback(() => {
    setError(createInitialErrorState());
  }, [setError]);

  if (!error) {
    return null;
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        システムエラーが発生しました
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          エラーの詳細がシステム管理者（戸張）に送信されました。
          <br />
          詳細を確認致しますので今しばらくお待ちくださいませ。
          <br />
          {error.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
};
