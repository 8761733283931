import { useEffect, useState } from 'react';
import { onSnapshot, orderBy, Query, query, where } from 'firebase/firestore';
import { ModelPhoto } from '../../entities';
import { modelPhotoCollection } from '../../infrastructures/firebase';

type usePhotosProps = {
  agencyId?: string;
  categoryId?: string;
};

export const usePhotos = ({
  agencyId,
  categoryId,
}: usePhotosProps): {
  isFetching: boolean;
  modelPhotos: ModelPhoto[];
} => {
  const [isFetching, setIsFetching] = useState(true);
  const [modelPhotos, setModelPhotos] = useState<ModelPhoto[]>([]);

  useEffect(() => {
    // agencyId, categoryId の両方とも指定なければ何もしない
    if (agencyId === undefined && categoryId === undefined) {
      return;
    }

    let q: Query<ModelPhoto> = modelPhotoCollection;

    if (agencyId) {
      q = query(modelPhotoCollection, where('agencyId', '==', agencyId));
    }

    if (categoryId) {
      q = query(modelPhotoCollection, where('categoryId', '==', categoryId));
    }

    // 登録日時が新しい順に並べる
    q = query(q, orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());

        if (data) {
          setModelPhotos(data);
        }

        setIsFetching(false);
      },
      (error) => {
        console.error('catch usePhotos error: ', error.message);
        setIsFetching(false);
      },
    );

    return unsubscribe;
  }, [agencyId, categoryId]);

  return { isFetching, modelPhotos };
};
