import { useCallback, useContext } from 'react';
import { labels } from '../../constants';
import { UiContext } from '../../contexts';
import { PredictionRepository } from '../../repositories';
import { useNetworker } from '../common/use-networker';

export const usePredictionEditTool = () => {
  const { setSnackbar } = useContext(UiContext);
  const networker = useNetworker();

  const startPredicting = useCallback(async (uid: string) => {
    await networker(async () => {
      // add object to firebase
      await PredictionRepository.add(uid);
      // display success message
      setSnackbar(labels.message.startPredicting);
    });
  }, []);

  return { startPredicting };
};
