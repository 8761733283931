import React, { Dispatch, SetStateAction } from 'react';

export type AuthStatus = 'LOADING' | 'UN_AUTHORIZED' | 'AUTHORIZED';

export const createinitialAuthStatus = (): AuthStatus => {
  return 'LOADING';
};

type ErrorState = Error | null;

export function createInitialErrorState(): ErrorState {
  return null;
}

type SnackbarState = string | null;

export function createInitialSnackbarState(): SnackbarState {
  return null;
}

type drawerIsOpenState = boolean;

export const createInitialdrawerIsOpenState = (): drawerIsOpenState => {
  return true;
};

type UiContextType = {
  authStatus: AuthStatus;
  setAuthStatus: Dispatch<SetStateAction<AuthStatus>>;
  error: ErrorState;
  setError: Dispatch<SetStateAction<ErrorState>>;
  snackbar: SnackbarState;
  setSnackbar: Dispatch<SetStateAction<SnackbarState>>;
  drawerIsOpen: drawerIsOpenState;
  setDrawerIsOpen: Dispatch<SetStateAction<drawerIsOpenState>>;
};

export const UiContext = React.createContext<UiContextType>({
  authStatus: createinitialAuthStatus(),
  setAuthStatus: () => {},
  error: createInitialErrorState(),
  setError: () => {},
  snackbar: createInitialSnackbarState(),
  setSnackbar: () => {},
  drawerIsOpen: createInitialdrawerIsOpenState(),
  setDrawerIsOpen: () => {},
});
